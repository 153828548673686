import React from 'react';
import Cakes from './Cakes';
import Splash from './Splash';
import About from './About';
import Thesis from './Thesis';
import MediaTexts from './MediaTexts';
import Collections from './Collections';
import SwollenHearts from './SwollenHearts';
import YechePies from './YechePies';
import CocoaStrawberry from './CocoaStrawberry';
import OneOfOneOneOne from './1-111';
import OneOfOneOneOneRecord from './1-111-record';
import Nav from './Nav'
import Panel from './Panel'

import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";

function App() {

  // const location = useLocation();
  // const hidePanelPages = ['/1-111', '/1-111-record', '/collections', '/'];
  // const hideNavPages = ['/1-111-record']

  return (
    <div className="App">
      {/* {!hideNavPages.includes(location.pathname) && <Nav />}
      {!hidePanelPages.includes(location.pathname) && <Panel />} */}

      <Routes>
        <Route index element={<OneOfOneOneOneRecord title="Record Your Mint"/>} />
        {/* <Route index element={<Splash title="Galerie Yeche Lange"/>} />
        <Route path="/about" element={<About title="About"/>} />
        <Route path="/thesis" element={<Thesis title="Collecting Thesis"/>} />
        <Route path="/media-texts" element={<MediaTexts title="Media Texts"/>} />
        <Route path="/collections" element={<Collections title="Collections"/>} />
        <Route path="/yeche-cakes" element={<Cakes title="Yeche Lange Cakes"/>} />
        <Route path="/swollen-hearts" element={<SwollenHearts title="Tournament for Swollen Hearts"/>} />
        <Route path="/yeche-pies" element={<YechePies title="Galerie Yeche Lange Pie Key Un"/>} />
        <Route path="/cocoa-freemint" element={<CocoaStrawberry title="Cocoa Strawberry Club Free Mint"/>} />
        <Route path="/1-111" element={<OneOfOneOneOne title="Creation Babies"/>} />
        <Route path="/1-111-record" element={<OneOfOneOneOneRecord title="Record Your Mint"/>} /> */}
      </Routes>
    </div>
  );
}

export default App;
