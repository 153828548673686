import React from 'react';

import { Contract, providers } from 'ethers';
import CREATION_BABIES_ABI from './creation-babies-abi.json';
import CREATION_WHITELIST from './creation-whitelist.json'; 
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import truncateEthAddress from 'truncate-eth-address';

import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';

import * as THREE from 'three'
import { useEffect, useRef, useMemo, useCallback, useContext, useState, useImperativeHandle, forwardRef, Suspense, createContext } from 'react';
import { Canvas, useFrame, useThree, useLoader, extend } from '@react-three/fiber';
import { useGLTF, Stats, shaderMaterial, Environment, Text, Html, Billboard, AdaptiveDpr } from "@react-three/drei"
import { EffectComposer, DepthOfField, SSAO, GodRays, Bloom, SMAA, HueSaturation, BrightnessContrast } from '@react-three/postprocessing';
import { BlendFunction, Resizer, KernelSize, OverrideMaterialManager } from 'postprocessing';
import CustomShaderMaterial from 'three-custom-shader-material'
import glsl from 'babel-plugin-glsl/macro'
import { useControls, Leva, folder } from 'leva';
import { ImprovedNoise } from 'three/examples/jsm/math/ImprovedNoise.js';
import './1-111.css';
import { BackSide, BoxGeometry, Clock, EqualStencilFunc, GLSLVersion, Group, MeshBasicMaterial, NotEqualStencilFunc, PlaneGeometry, TextureLoader } from 'three';
import { Camera } from 'three';
import { PerspectiveCamera } from 'three';
import { MeshStandardMaterial } from 'three';
import { lerp, clamp, smoothstep, mapLinear } from 'three/src/math/MathUtils';
import { matchRoutes } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import CameraControls from 'camera-controls';
import Draggable from 'react-draggable';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {Player} from 'react-simple-player';

const INFURA_KEY = '4ebebc5a34f44308ab4df40a00f21d34';

const CREATION_BABIES_ADDRESS = '0x96C161A071faA7a6cE24C591Cc697A9BC15dF72d';
const targetChainName = 'mainnet';
const targetChainID = 1;
const wrongNetworkMessage = "wrong network, switch to " + targetChainName;

//dirty trick to hide warnings
window.console.warn = () => {};

CameraControls.install({ THREE })

extend({ CameraControls })

const SceneContext = createContext({});

function mapRange(value, x1, y1, x2, y2) {
    return (value - x1) * (y2 - x2) / (y1 - x1) + x2;
}

const CamControls = React.forwardRef(({minDistance, maxDistance, useFocalOffset}, ref) => {
    const camera = useThree((state) => state.camera);
    const gl = useThree((state) => state.gl);
    useFrame((state, delta) => ref.current.update(delta));

    useEffect(() => {
        let initOffset = isMobile ? 0.8 : 1.5;

        ref.current.setFocalOffset(useFocalOffset ? initOffset : 0, 0, 0, true);
    }, [ref]);

    return (
        <cameraControls 
        ref={ref} 
        distance={isMobile ? lerp(minDistance, maxDistance, 0.8) : lerp(minDistance, maxDistance, 0.5)}
        minDistance={minDistance}
        maxDistance={maxDistance}
        dollySpeed={isMobile ? 0.5 : 0.1}
        dampingFactor={0.05}
        // truckSpeed={0.0}
        draggingDampingFactor={0.05}
        azimuthRotateSpeed={0.5}
        polarRotateSpeed={0.5}
        minPolarAngle={0}
        args={[camera, gl.domElement]} 
        />
    );
});

function HeartParticles({count, matcapTex, heartMatAnimSpeed}) {
    const mesh = useRef();
    const dummy = useMemo(() => new THREE.Object3D(), []);

    const gradientTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/plasma.jpg");

    const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + '/models/heart2.glb')

    const meshWorldPos = new THREE.Vector3();

    const { heartColSat, heartColLightness } = useControls('heart particles', {
        heartColSat: {
            value: 0.5,
            min: 0,
            max: 1,
            step: 0.01,
        },
        heartColLightness: {
            value: 0.5,
            min: 0,
            max: 1,
            step: 0.01,
        },
    });

    const white = new THREE.Color(1.0,1.0,1.0);

    const particles = useMemo(() => {
        const temp = [];
        for (let i = 0; i < count; i++) {
            let randScale = Math.random() * 0.1 + 0.1;

            let speed = Math.random() * 0.05 + 0.02;

            let ang = Math.random() * 2 * Math.PI;
            let maxRad = Math.random() * 5 + 5;
            let rad = Math.random() * maxRad;

            //random red color
            let col = new THREE.Color();
            //set color to random
            col.setHSL(Math.random(), Math.random(), Math.random());

            temp.push({scale: randScale, speed: speed, ang: ang, col: col, maxRad: maxRad, rad: rad, x: 0, y: 0, z: 0});
        }
        return temp;
    }, [count]);

    // useEffect(() => {
    //     mesh.current.rotateZ(Math.PI);
    // }, [mesh]);

    useFrame((state) => {

        //get world position of mesh
        mesh.current.getWorldPosition(meshWorldPos);

        let bigHeartPulse = Math.sin(state.clock.elapsedTime * heartMatAnimSpeed) * 0.5 + 0.5;
        bigHeartPulse = clamp(bigHeartPulse, 0.0, 1.0);
        bigHeartPulse = 1.0 - bigHeartPulse;

        let bigHeartOffsetSize = 5.0;

        particles.forEach((particle, i) => {

            let offsetY = Math.cos(particle.ang) * bigHeartOffsetSize * bigHeartPulse;
            let offsetZ = Math.sin(particle.ang) * bigHeartOffsetSize * bigHeartPulse;

            let animPct = particle.rad / particle.maxRad;

            particle.ang += particle.speed / 10.0;
            particle.rad = (particle.rad + particle.speed) % particle.maxRad;

            //move the dummy to mesh world position

            particle.x = 0;//Math.sin(particle.ang) * animPct * particle.maxRad;
            particle.y = Math.cos(particle.ang) * animPct * particle.maxRad + offsetY;
            particle.z = Math.sin(particle.ang) * animPct * particle.maxRad + offsetZ;

            //get world position of mesh
            
            dummy.position.set(particle.x, particle.y, particle.z);
            
            //set particle y rotation based on particle ang
            dummy.rotation.y = particle.ang * 10.0;
            
            //rotate the object to face the camera
            
            let scale = particle.scale * Math.sin(animPct * Math.PI);
            
            dummy.scale.set(scale, scale, scale);
            //dummy.lookAt(state.camera.position);

            //set dummy matrix to mesh matrix
            
            dummy.updateMatrix();

            mesh.current.setMatrixAt(i, dummy.matrix);

            //mix particle col with white based on bigHeartPulse
            let col = particle.col.clone();
            col.lerp(white, bigHeartPulse);
            
            mesh.current.setColorAt(i, col);
        });

        //billboard mesh to camera
        //mesh.current.lookAt(state.camera.position);
        mesh.current.instanceMatrix.needsUpdate = true;
        mesh.current.instanceColor.needsUpdate = true;
    });

    return(
        <instancedMesh ref={mesh} args={[null, null, count]} geometry={nodes.heart.geometry} renderOrder={100}>
            {/*<planeGeometry attach="geometry" args={[1, 1]} />*/}
            <meshMatcapMaterial 
                matcap={matcapTex}
                opacity={0.9}
                transparent={true}
                attach="material" 
                blending={THREE.AdditiveBlending}
            />

            {/* <CustomShaderMaterial
                baseMaterial={THREE.MeshMatcapMaterial}
                vertexShader={
                    `
                    varying vec2 myUv;

                    varying float gradientOffset;

                    void main() {
                        myUv = uv;

                        vec4 globalPos = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

                        gradientOffset = globalPos.z;

                    }                        
                    `
                }
                fragmentShader={
                ` 
                    uniform sampler2D gradient;
                    varying float gradientOffset;

                    void main() {
                        //map gl_FragColor to gradient texture
                        float offset = csm_DiffuseColor.r;

                        vec4 gradientColor = texture2D(gradient, vec2(offset, 0.5));
                        csm_DiffuseColor = csm_DiffuseColor;
                    }
                `
                }
                uniforms={{
                    gradient: {
                        value: gradientTex
                    },
                }}
                opacity={0.5}
                transparent={true}
                attach="material" 
                // ...
            /> */}
        </instancedMesh>
    );
}

function MudMat() {
    const baseTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mud_basecolor.jpg");
    const normalTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mudmat_Normal.jpg");

    const { roughness, metalness, matCol, clearcoat, clearcoatRoughness, normalMapStrength, envMapIntensity } = useControls('mud material',{
        roughness: { value: 0.0, min: 0, max: 1, step: 0.01 },
        metalness: { value: 0.72, min: 0, max: 1, step: 0.01 },
        clearcoat: { value: 0.0, min: 0, max: 1, step: 0.01 },
        clearcoatRoughness: { value: 0.34, min: 0, max: 1, step: 0.01 },
        normalMapStrength: { value: 3.55, min: 0, max: 5, step: 0.01 },
        envMapIntensity: { value: 3.2, min: 0, max: 10, step: 0.01 },
        matCol: '#beabab',
    });

    useEffect(() => {
        baseTex.wrapS = baseTex.wrapT = THREE.RepeatWrapping;
        baseTex.repeat.set( 15, 15 );

        normalTex.wrapS = normalTex.wrapT = THREE.RepeatWrapping;
        normalTex.repeat.set( 15, 15 );

    }, [baseTex, normalTex]);

    return (
        <meshPhysicalMaterial 
        map={baseTex}
        map-flipY={false}
        normalMap={normalTex} 
        normalMap-flipY={false}
        receiveShadow={true}
        normalMap-encoding={THREE.LinearEncoding}
        normalScale={[normalMapStrength,normalMapStrength]} 
        // roughnessMap={roughnessMap}
        roughness={roughness} 
        metalness={metalness} 
        clearcoat={clearcoat} 
        clearcoatRoughness={clearcoatRoughness} 
        color={matCol} 
        envMapIntensity={envMapIntensity}
        transparent={true}
        />
    );
}

function PixarCloudMat({cloudColor, cloudsNoiseStrength, cloudsNoiseScale, cloudsFresnelPow, cloudsFresnelBoost}) {
    const cloudsMatRef = useRef();

    useFrame((state, delta) => {
        if(cloudsMatRef.current) {
            cloudsMatRef.current.uniforms.uTime.value += delta;
        }
    });

    return(
        <CustomShaderMaterial
        ref={cloudsMatRef}
        baseMaterial={THREE.MeshBasicMaterial}
        vertexShader={
            /* glsl */ 
            ` 
            uniform float uTime;
            uniform float noiseAnimStrength;
            uniform float cloudsNoiseScale;

            varying vec3 vEye;
            varying vec3 vNorm;

            float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
            vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
            vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}

            float noise(vec3 p){
                vec3 a = floor(p);
                vec3 d = p - a;
                d = d * d * (3.0 - 2.0 * d);

                vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
                vec4 k1 = perm(b.xyxy);
                vec4 k2 = perm(k1.xyxy + b.zzww);

                vec4 c = k2 + a.zzzz;
                vec4 k3 = perm(c);
                vec4 k4 = perm(c + 1.0);

                vec4 o1 = fract(k3 * (1.0 / 41.0));
                vec4 o2 = fract(k4 * (1.0 / 41.0));

                vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
                vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);

                return o4.y * d.y + o4.x * (1.0 - d.y);
            }

            void main() {
                vec3 pos = position;

                //displace pos along csm_Normal
                vec3 timeOffset = vec3(0., 0., uTime);
                pos += normal * noise(position * cloudsNoiseScale + timeOffset) * noiseAnimStrength;
                
                vNorm = normalize(normalMatrix * normal);
                vEye = normalize(vec3(modelViewMatrix * vec4(position, 1.0)).xyz);

                csm_Position = pos;
            }                        
            `
        }
        fragmentShader={/* glsl */ 
        ` 
            varying vec3 vNorm;
            varying vec3 vEye;

            uniform float cloudsFresnelBoost;
            uniform float cloudsFresnelPow;

            varying float dist;
            
            void main() {
                float fresnelTerm = -min(dot(vEye, normalize(vNorm) ), 0.0);
                fresnelTerm = clamp(fresnelTerm, 0., 1.);
                // vec3 colOut = mix(vec3(0.0), vec3(1.0), pow(fresnelTerm, cloudsFresnelPow) * cloudsFresnelBoost);
                // csm_DiffuseColor = vec4(colOut, 1.0);
                csm_DiffuseColor.a = pow(fresnelTerm, cloudsFresnelPow) * cloudsFresnelBoost;
            }
        `
        }
        uniforms={{
            uTime: {
                value: 0,
            },
            noiseAnimStrength: {
                value: cloudsNoiseStrength,
            },
            cloudsFresnelBoost: {
                value: cloudsFresnelBoost,
            },
            cloudsNoiseScale: {
                value: cloudsNoiseScale,
            },
            cloudsFresnelPow: {
                value: cloudsFresnelPow,
            }
        }}
        color={cloudColor}
        transparent={true}
        // blending={THREE.AdditiveBlending}
        depthWrite={false}
        depthTest={false}
        castShadow={false}
        fog={false}
        // ...
        />
    );
}

function PixarCloud({geometry, startPos, startRot, gridScale, scale}) {

    const { 
        cloudsNoiseStrength, 
        cloudsNoiseScale, 
        cloudsFresnelPow, 
        cloudsFresnelBoost,
        cloudsDriftSpeed,
        cloudColor
    } = useControls('pixar clouds', {
        cloudsNoiseStrength: {
            value: 3.8,
            min: 0.0,
            max: 5.0,
            step: 0.01,
        },
        cloudsNoiseScale: {
            value: 0.1,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        cloudsFresnelPow: {
            value: 6.1,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        cloudsFresnelBoost: {
            value: 1.0,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        cloudsDriftSpeed: {
            value: 0.03,
            min: 0.0,
            max: 0.1,
            step: 0.01,
        },
        cloudColor: '#b4b4b4',
    });


    const meshRef = useRef();
    const origin = new THREE.Vector3(0,0,0);

    useFrame((state, delta) => {
        if(meshRef.current) {
            //gradually animate cloud across the z axis, loop back to start when it reaches the end
            meshRef.current.position.z += cloudsDriftSpeed;
            if(meshRef.current.position.z > gridScale / 2.0) {
                meshRef.current.position.z = -gridScale / 2.0;
            }

            //calculate x,z dist from origin
            let dist = meshRef.current.position.distanceTo(origin);
            let dipAmt = smoothstep(30, 25, dist) * -8.0;

            //dip cloud when it gets close to mountain so it doesn't clip 
            let newY = startPos.y + dipAmt;

            meshRef.current.position.y = newY;

            //fade opacity of clouds as it approaches edge of grid

            //calculate dist to either edge, -gridScale/2.0 or gridScale/2.0, whichever is closer
            const distToEdge = Math.min(Math.abs(meshRef.current.position.z - gridScale/2.0), Math.abs(meshRef.current.position.z + gridScale/2.0));

            const opacity = Math.min(distToEdge / 100.0, 1.0);
            meshRef.current.material.uniforms.cloudsFresnelBoost.value = opacity;
        }
    });

    return(
        <mesh 
        ref={meshRef}
        renderOrder={-1000}
        geometry={geometry} 
        position={startPos} 
        rotation={startRot} 
        scale={scale}>
            <PixarCloudMat 
            cloudsNoiseStrength={cloudsNoiseStrength} 
            cloudsNoiseScale={cloudsNoiseScale}
            cloudsFresnelPow={cloudsFresnelPow}
            cloudsFresnelBoost={cloudsFresnelBoost}
            cloudColor={cloudColor}
            />
        </mesh>
    );
}

function PixarClouds() {
    const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + '/models/clouds.glb')

    //10x10 grid of clouds 
    const gridSize = 8;
    const gridScale = 300;
    const clouds = useRef([]);
    const cloudsStartPos = useRef([]);
    const cloudsStartRot = useRef([]);
    const cloudsScale = useRef([]);

    useEffect(() => {
        //populate array with random node geometry 
        const cloudsGeo = [];

        for (const [key, value] of Object.entries(nodes)) {
            if(key.startsWith("clouds")) {
                cloudsGeo.push(value.geometry);
            }
        }

        let centerOffset = gridScale / 2;
        let gridWidth = gridScale / gridSize;

        let cloudLevel = -20;
        //let cloudFlattenFactor = 1.1;

        for(let x = 0; x < gridSize; x++) {
            for(let y = 0; y < gridSize; y++) {
                let i = x + y * gridSize;
                let geo = cloudsGeo[Math.floor(Math.random() * cloudsGeo.length)];

                let startPos = new THREE.Vector3(x * gridWidth - centerOffset, cloudLevel, y * gridWidth - centerOffset);
                
                cloudsStartPos.current.push(startPos);
                clouds.current.push(geo);

                let startRot = new THREE.Euler(0, (Math.random() - 0.5) * 2.0 * Math.PI/2.0, 0);
                cloudsStartRot.current.push(startRot);

                let randScale = Math.random() * 0.5 + 0.8;

                let scale = new THREE.Vector3(randScale, randScale, randScale);
                cloudsScale.current.push(scale);
            }
        }

    }, [nodes]);

    return(
        <>
        {clouds.current.map((cloud, i) => {
            return(
                <PixarCloud 
                    key={i}
                    geometry={cloud}
                    startPos={cloudsStartPos.current[i]}
                    startRot={cloudsStartRot.current[i]}
                    scale={cloudsScale.current[i]}
                    gridScale={gridScale}
                />
            )
        })}
        </>
    );
}

function Timeline({mintedWorks, nodes, playTimeline}) {

    const [numMintedWorks, setNumMintedWorks] = useState(0);

    const artworkCards = useRef([]);

    const playhead = useRef(null);

    const lineThick = useRef(null);

    const timelineLine = useRef(null);

    const timelinePoints = useRef([]);

    const artworkFell = useRef([]);

    const canvasRef = useRef(null);

    // const showedPopup = useRef(false);
    const showedConnect = useRef(false);

    const playheadSpeed = 1.0; //was 4

    const startTimelineFrame = 10;

    const seekedPct = useRef(0.0);

    const frameCount = useRef(0);
    const startTime = useRef(0.0);

    // const { popup } = useContext(SceneContext);

    function gotTimelineClick(e) {
        //get percentage along timelineLine of click
        const rect = timelineLine.current.getBoundingClientRect();
        const x = e.clientX - rect.left;

        const percent = x / rect.width;
        console.log('percent ' + percent);
        seekedPct.current = percent;
        frameCount.current = startTimelineFrame - 1;

        mintedWorks.forEach((work, i) => {
            if(work.timeMintedPerc > seekedPct.current) {
                timelinePoints.current[i].style.animation = 'pulsePoint 0s reverse';
                artworkFell.current[i] = false;
            }
        });
        // startTime.current = state.clock.elapsedTime;
    }

    useEffect(() => {
        // canvasRef.current = document.getElementsByTagName('canvas')[0];
        // canvasRef.current.style.filter = 'blur(5px)';

        if(mintedWorks.length > 0) {
            console.log('num minted works ' + mintedWorks.length);

            playhead.current = document.getElementsByClassName("Timeline-playhead")[0];

            lineThick.current = document.getElementsByClassName("Timeline-line-thick")[0];

            timelineLine.current = document.getElementsByClassName("Timeline-line")[0];

            //add event listener to timeline to get clicks, call gotTimelineClick
            timelineLine.current.addEventListener("click", gotTimelineClick);

            //setup timeline points
            timelinePoints.current = document.getElementsByClassName("Timeline-point");

            let cards = [];

            //setup artworks 
            let glbCards = [];
            for (const [key, value] of Object.entries(nodes)) {
                if(key.startsWith("jared_111")) {
                    glbCards.push(value);
                }
            }

            //sort cards by name
            glbCards.sort((a, b) => (a.name > b.name) ? 1 : -1);

            //get artworks from nodes
            for(let i = 0; i < glbCards.length; i++) {
                let glbCard = glbCards[i];
                
                let card = {
                    name: glbCard.name,
                    position: glbCard.position,
                    rot: glbCard.rotation,
                    geo: glbCard.geometry,
                    color: '0xffffff',
                    owner: '0x0000000000000000000000000000000000000000',
                }

                cards.push(card);
                artworkFell.current.push(false);

                if(cards.length === mintedWorks.length) {
                    break;
                }
            }

            let linearGradient = 'linear-gradient(90deg,';

            for(let i = 0; i < mintedWorks.length; i++) {
                cards[i].color = mintedWorks[i].color;
                cards[i].owner = mintedWorks[i].owner;
                // console.log('setting owner of card ' + i + ' to ' + cards[i].owner);

                let pct = (mintedWorks[i].timeMintedPerc * 100);

                linearGradient += mintedWorks[i].color + ' ' + pct + '%, ';
            }
            
            linearGradient += '#ffffff 100%)';

            document.getElementsByClassName("Timeline-line")[0].style.background = linearGradient;

            artworkCards.current = cards;    
            setNumMintedWorks(mintedWorks.length);
        }

    }, [mintedWorks, nodes]);

    useFrame((state, delta) => {

        if(playTimeline) {
            frameCount.current += 1;

            if(frameCount.current == startTimelineFrame ) {
                startTime.current = state.clock.elapsedTime;

                //show connect button once the scene is going 
                // if(!showedConnect.current) {
                //     let connectButton = document.getElementsByClassName("Connect-button")[0];
                    // connectButton.style.display = 'block';
                    // connectButton.classList.add('visible');
                    // showedConnect.current = true;
                // }
                
                // if(!showedPopup.current) {
                //     popup.current.classList.add('visible');
                //     showedPopup.current = true;    
                // }

                // else {
                //     //loop over the minted works and set animation to "pulsePoint 1s backwards" if work.timeMintedPerc > seekedPct
                //     mintedWorks.forEach((work, i) => {
                //         if(work.timeMintedPerc > seekedPct.current) {
                //             timelinePoints.current[i].style.animation = 'pulsePoint 1s reverse';
                //             artworkFell.current[i] = false;
                //         }
                //     });
                // }
            }
    
            // else if(frameCount.current > startTimelineFrame) {
            let newPercent = frameCount.current > startTimelineFrame ? (state.clock.elapsedTime - startTime.current) / playheadSpeed : 0;
            newPercent += seekedPct.current * 100.0;
            //clamp newPercent to 0-100
            newPercent = Math.max(0, Math.min(100, newPercent));
    
            if(newPercent < 100 && playhead.current && lineThick.current && timelinePoints.current && mintedWorks) {
                //move playhead along timeline
                playhead.current.style.left = newPercent + "%";
    
                lineThick.current.style.width = (100.0 - newPercent) + "%";
    
                //let fallBefore = 3.0;
    
                //check if playhead is over artwork
                mintedWorks.forEach((work, i) => {
                    if(!artworkFell.current[i]) {
                        let artworkPct = work.timeMintedPerc * 100.0;
    
                        if(newPercent > artworkPct) {
                            artworkFell.current[i] = true;
                            console.log('set artwork fell to true');
    
                            timelinePoints.current[i].style.animation = 'none';

                            //trigger reflow to restart animation
                            void timelinePoints.current[i].offsetWidth;

                            //add css animation pulsePoint to timeline point
                            timelinePoints.current[i].style.animation = "pulsePoint 1s forwards";
                        }
                    }
                });
    
            }        
        }

    });

    return(
        <>
        {numMintedWorks > 0 && artworkCards.current.map((card, i) => {
            return(
                <ArtworkCard 
                paintingID={i}
                ref={artworkFell}
                key={card.name} 
                pos={card.position} 
                rot={card.rot}
                geo={card.geo}
                col={card.color}
                owner={card.owner}
                />
            )
        })}
        </>
    );
}

function QuestionMarkMat() {
    const { 
        questionBlendMode,
        questionPlasmaSpeed, 
        questionPlasmaTiling, 
        questionHueShift1, 
        questionHueShift2,
        questionColMin1,
        questionColMax1,
        questionColMin2,
        questionColMax2,
        questionBrightness1,
        questionBrightness2,
        questionSaturation1,
        questionSaturation2,
        questionMetalness1,
        questionMetalness2,
        questionRoughness1,
        questionRoughness2,
        questionOpacity1,
        questionOpacity2,
    } = useControls("question mark paintings", { 
        questionBlendMode: { value: 1, min: 0, max: 4, step: 1 },
        questionPlasmaSpeed: { value: 34.0, min: 0.0, max: 80.0 },
        questionPlasmaTiling: { value: 200.0, min: 100.0, max: 2000.0 },
        questionHueShift1: { value: 0.64, min: 0.0, max: Math.PI * 2.0 },
        questionHueShift2: { value: 0.6, min: 0.0, max: Math.PI * 2.0 },
        questionColMin1: { value: 0.0, min: 0.0, max: 1.0 },
        questionColMin2: { value: 0.75, min: 0.0, max: 1.0 },
        questionColMax1: { value: 0.5, min: 0.0, max: 1.0 },
        questionColMax2: { value: 1.0, min: 0.0, max: 1.0 },
        questionBrightness1: { value: 1.0, min: 0.0, max: 20.0 },
        questionBrightness2: { value: 8.2, min: 0.0, max: 20.0 },
        questionSaturation1: { value: 2.0, min: 0.0, max: 5.0 },
        questionSaturation2: { value: 3.05, min: 0.0, max: 5.0 },
        questionMetalness1: { value: 1.0, min: 0.0, max: 1.0 },
        questionMetalness2: { value: 0.85, min: 0.0, max: 1.0 },
        questionRoughness1: { value: 0.0, min: 0.0, max: 1.0 },
        questionRoughness2: { value: 0.53, min: 0.0, max: 1.0 },
        questionOpacity1: { value: 0.69, min: 0.0, max: 1.0 },
        questionOpacity2: { value: 0.87, min: 0.0, max: 1.0 },
    });

    const artMatRef = useRef(null);
    const questionMarkTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/question_mark.jpg");
    const gradientTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/infra.jpg");
    // const questionRoughness = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/cape_roughness.jpg");

    useFrame((state, delta) => {
        if(artMatRef.current) {
            artMatRef.current.uniforms.uTime.value += delta;
        }
    });

    return(
        <CustomShaderMaterial
        ref={artMatRef}
        baseMaterial={THREE.MeshStandardMaterial}
        vertexShader={
        `
        varying vec2 myUv;

        void main() {
            myUv = uv;
        }                        
        `
        }
        fragmentShader={
        ` 
        uniform sampler2D questionMarkMask;
        // uniform sampler2D questionRoughness;
        uniform sampler2D gradient;
        uniform float uTime;

        uniform float offset;

        uniform float plasmaTiling;
        uniform float plasmaSpeed;

        uniform float colMin1;
        uniform float colMax1;

        uniform float colMin2;
        uniform float colMax2;

        uniform float hueShift1;
        uniform float hueShift2;

        uniform float brightness1;
        uniform float brightness2;

        uniform float saturation1;
        uniform float saturation2;

        uniform float metalness1;
        uniform float metalness2;

        uniform float roughness1;
        uniform float roughness2;

        uniform float opacity1;
        uniform float opacity2;

        varying vec2 myUv;

        vec3 hueShift( vec3 color, float hueAdjust ){

            const vec3  kRGBToYPrime = vec3 (0.299, 0.587, 0.114);
            const vec3  kRGBToI      = vec3 (0.596, -0.275, -0.321);
            const vec3  kRGBToQ      = vec3 (0.212, -0.523, 0.311);
        
            const vec3  kYIQToR     = vec3 (1.0, 0.956, 0.621);
            const vec3  kYIQToG     = vec3 (1.0, -0.272, -0.647);
            const vec3  kYIQToB     = vec3 (1.0, -1.107, 1.704);
        
            float   YPrime  = dot (color, kRGBToYPrime);
            float   I       = dot (color, kRGBToI);
            float   Q       = dot (color, kRGBToQ);
            float   hue     = atan (Q, I);
            float   chroma  = sqrt (I * I + Q * Q);
        
            hue += hueAdjust;
        
            Q = chroma * sin (hue);
            I = chroma * cos (hue);
        
            vec3    yIQ   = vec3 (YPrime, I, Q);
        
            return vec3( dot (yIQ, kYIQToR), dot (yIQ, kYIQToG), dot (yIQ, kYIQToB) );
        }                       

        vec3 czm_saturation(vec3 rgb, float adjustment)
        {
            // Algorithm from Chapter 16 of OpenGL Shading Language
            const vec3 W = vec3(0.2125, 0.7154, 0.0721);
            vec3 intensity = vec3(dot(rgb, W));
            return mix(intensity, rgb, adjustment);
        }        

        void main() {
            vec2 vp = vec2(plasmaTiling, plasmaTiling);
            float t = (uTime + offset) * plasmaSpeed;
            vec2 p0 = (myUv - 0.5) * vp;
            vec2 hvp = vp * 0.5;
            vec2 p1d = vec2(cos( t / 98.0),  sin( t / 178.0)) * hvp - p0;
            vec2 p2d = vec2(sin(-t / 124.0), cos(-t / 104.0)) * hvp - p0;
            vec2 p3d = vec2(cos(-t / 165.0), cos( t / 45.0))  * hvp - p0;
            float plasma = 0.5 + 0.5 * (
                cos(length(p1d) / 30.0) +
                cos(length(p2d) / 20.0) +
                sin(length(p3d) / 25.0) * sin(p3d.x / 20.0) * sin(p3d.y / 15.0));

            vec2 myUvFlipped = vec2(myUv.x, 1.0 - myUv.y);

            float mask = texture2D(questionMarkMask, myUvFlipped).r;
            
            float col1Val = mix(colMin1, colMax1, plasma);
            vec3 col1 = texture2D(gradient, vec2(col1Val, 0.5)).rgb;
            col1 = hueShift(col1, hueShift1);
            col1 *= brightness1;
            col1 = czm_saturation(col1, saturation1);
            
            float col2Val = mix(colMin2, colMax2, plasma);
            vec3 col2 = texture2D(gradient, vec2(col2Val, 0.5)).rgb;
            col2 = hueShift(col2, hueShift2);
            col2 *= brightness2;    
            col2 = czm_saturation(col2, saturation2);

            //lerp between inverse and col based on mask
            vec3 finalCol = mix(col1, col2, 1.0 - mask);

            float alpha = mix(opacity1, opacity2, 1.0 - mask);

            csm_DiffuseColor = vec4(finalCol, alpha);

            vec2 roughnessUvTiled = vec2(myUv.x * 4.0, myUv.y * 4.0);

            float roughness = mix(roughness1, roughness2, 1.0 - mask);
            
            csm_Roughness = roughness;
            csm_Metalness = mix(metalness1, metalness2, 1.0 - mask);
        }
        `
        }

        uniforms={{
            plasmaTiling: {
                value: questionPlasmaTiling
            },
            plasmaSpeed: {
                value: questionPlasmaSpeed
            },
            hueShift1: {
                value: questionHueShift1
            },
            hueShift2: {
                value: questionHueShift2
            },
            colMin1: {
                value: questionColMin1
            },
            colMax1: {
                value: questionColMax1
            },
            colMin2: {
                value: questionColMin2
            },
            colMax2: {
                value: questionColMax2
            },
            questionMarkMask: {
                value: questionMarkTex,
            },
            // questionRoughness: {
            //     value: questionRoughness
            // },
            brightness1: {
                value: questionBrightness1
            },
            brightness2: {
                value: questionBrightness2
            },
            saturation1: {
                value: questionSaturation1
            },
            saturation2: {
                value: questionSaturation2
            },
            metalness1: {
                value: questionMetalness1
            },
            metalness2: {
                value: questionMetalness2
            },
            roughness1: {
                value: questionRoughness1
            },
            roughness2: {
                value: questionRoughness2
            },
            opacity1: {
                value: questionOpacity1
            },
            opacity2: {
                value: questionOpacity2
            },
            gradient: {
                value: gradientTex,
            },
            uTime: {
                value: 0.0,
            }, 
            offset: {
                value: 111.11,
            }

        }}
        color={0xffffff}
        transparent={true}
        castShadow={true}
        metallic={0.5}
        envMapIntensity={1.0}
        side={THREE.DoubleSide}
        blending={questionBlendMode}
        // ...
        />
    );
}

const ArtworkCard = forwardRef(({owner, col, pos, geo, rot, paintingID}, ref) => {

    const { showMountainPercent, mountainZoom, cardFallingZoom, mountainOffsetDist } = useControls("camera recording", {
        showMountainPercent: { value: 0.3, min: 0.0, max: 1.0, step: 0.01 },
        mountainZoom: { value: 4.0, min: 0.0, max: 20.0, step: 0.01 },
        cardFallingZoom: { value: 10.0, min: 0.0, max: 20.0, step: 0.01 },
        mountainOffsetDist: { value: 20.0, min: 0.0, max: 20.0, step: 0.01 },
    })
    
    const vector = new THREE.Vector3();
    const vector2 = new THREE.Vector3();
    
    const velocity = new THREE.Vector3(0,0,0);
    
    const groupRef = useRef();
    const meshRef = useRef();
    const annotationRef = useRef();

    const annotationVisible = useRef(false);

    const paintingTex = useRef(null);

    const highlightAmt = useRef(0.0);
    
    const highlightCol = new THREE.Color(col);

    const landPos = useRef(new THREE.Vector3(0,0,0));

    const [textCol, setTextCol] = useState(new THREE.Color(0x000000));
    const [randTextOffset, setRandTextOffset] = useState('0%');
    const [ownerText, setOwnerText] = useState('');

    //const [showAnnotation, setShowAnnotation] = useState(false);

    const { controls, mintedWorkID, recording, provider, previewHover, previewPainting } = useContext(SceneContext);

    //todo remove this
    // const questionMarkTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/question_mark.jpg");
    // const questionRoughness = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/cape_roughness.jpg");
    // const gradientTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/infra.jpg");
     
    const [paintingVisible, setPaintingVisible] = useState(false);

    const { paintingSat, usePainting } = useControls("paintings", {
        paintingSat: { value: 0.8, min: 0.0, max: 5.0 },
        usePainting: { value: true },
    });

    const worldLookPos = new THREE.Vector3();

    const annotationAge = useRef(0);

    const artMatRef = useRef(null);

    const didResetWork = useRef(false);

    const maxAnnotationAge = 600;
    const hideAnnotationAge = 700;

    //const [noiseOffsetAng, setNoiseOffsetAng] = useState(0.0);
    const perlin = new ImprovedNoise();

    const fallDist = 50;

    const pivotYOffset = 0.5;

    const slide = useRef(0.0);
    const slideDist = 0.25;

    const { swayAmplitude, swaySpeed, swayNoiseScale } = useControls('art sway', 
    {
        swayAmplitude: { value: 0.5, min: 0.0, max: 5.0},
        swaySpeed: { value: 1.5, min: 0.0, max: 4.0},
        swayNoiseScale: { value: 0.1, min: 0.0, max: 1.0},
    });

    function resetArtwork() {
        let startPos = pos.clone();
        startPos.y = startPos.y + fallDist;

        groupRef.current.position.set(startPos.x, startPos.y, startPos.z);
        groupRef.current.updateMatrixWorld();

        slide.current = 0.0;
        // triggeredSplatter.current = false;
    }

    function showAnnotation() {
        if(annotationRef.current) {
            annotationRef.current.style.display = "block";
            //annotationRef.current.style.animation = 'none';
            //show annotation
            annotationRef.current.style.animation = "fadeIn 2s forwards";
        }
    }

    function hideAnnotation() {
        if(annotationRef.current) {
            annotationRef.current.style.display = "none";
        }
    }

    // useEffect(() => {
    //     console.log('my paintingID ' + paintingID);
    //     console.log('my owner ' + owner);
    // }, []);

    useEffect(() => {
        if(usePainting && paintingVisible) {
            artMatRef.current.uniforms.paintingTex.value = paintingTex.current;
            artMatRef.current.needsUpdate = true;
        }

    }, [usePainting]);

    useEffect(() => {
            //PAINTING DROPPED
            if(paintingVisible) {

                //loading painting tex
                let paintingFile = process.env.PUBLIC_URL + "/textures/paintings/" +  (paintingID + 1).toString() + ".jpg";
                // console.log("paintingFile", paintingFile);

                const texLoader = new THREE.TextureLoader();
                texLoader.load(paintingFile, (texture) => {
                    paintingTex.current = texture;
                    if(artMatRef.current) {
                        artMatRef.current.uniforms.paintingTex.value = texture;
                        artMatRef.current.needsUpdate = true;
                    }
                });

                //set text color randomly
                // let randColNum = Math.random() * 0.3 + 0.7;
                // let randCol = '#'+(randColNum * 0xFFFFFF << 0).toString(16).padStart(6, '0');
                setTextCol(col);

                //set randTextOffset to string between -200% and -400%
                let randOffset = Math.floor(Math.random() * 200) + 100;
                //generate random sign '-' or ''
                let randSign = Math.random() < 0.5 ? '-' : '';
                setRandTextOffset(randSign + randOffset + '%');
                
                pos.y -= pivotYOffset;

                landPos.current = pos;
                landPos.current.y += slideDist;

                //try to get ENS name for address
                if(provider) {
                    provider.lookupAddress(owner).then((name) => {
                        if(name) {
                            console.log('got ens name ' + name);
                            setOwnerText(name);
                        } 
                        else {
                            if(owner.length > 12) {
                                let ownerShort = owner.substring(0, 10) + '...';
                                setOwnerText(ownerShort);    
                            }
            
                            else {
                                setOwnerText(owner);
                            }            
                        }
                    });
                }

                else {
                    if(owner.length > 12) {
                        let ownerShort = owner.substring(0, 10) + '...';
                        setOwnerText(ownerShort);    
                    }

                    else {
                        setOwnerText(owner);
                    }            
                }

                annotationAge.current = 0;

                annotationVisible.current = false;
                hideAnnotation();

                let startPos = pos.clone();
                startPos.y = startPos.y + fallDist;

                groupRef.current.position.set(startPos.x, startPos.y, startPos.z);
                groupRef.current.updateMatrixWorld();

                groupRef.current.visible = false;
            }
    }, [paintingVisible]);

    useFrame((state, delta) => {
        //update time for question mark card
        if(ref.current[paintingID]) {
            if(!paintingVisible) {
                //BAD 
                setPaintingVisible(true);
            }

            if(groupRef.current) {        
                if(annotationAge.current < hideAnnotationAge && !annotationVisible.current) {
                    showAnnotation();
                    annotationVisible.current = true;
        
                    groupRef.current.visible = true;
                }
        
                //update annotation 
                if(annotationVisible.current) {
                    annotationAge.current += 1;
        
                    if(annotationAge.current > maxAnnotationAge) {
                        highlightAmt.current -= 0.1;
                        highlightAmt.current = Math.max(highlightAmt.current, 0.0);
                    }
        
                    else if(annotationAge.current < maxAnnotationAge) {
                        highlightAmt.current += 0.1;
                        highlightAmt.current = Math.min(highlightAmt.current, 1.0);
                    }
        
                    if(annotationAge.current == maxAnnotationAge && annotationRef.current) {
                        //faed out annotation
                        annotationRef.current.style.animation = "fadeOut 2s forwards";
                    }
        
                    else if(annotationAge.current > hideAnnotationAge) {
                        //setShowAnnotation(false);
                        annotationVisible.current = false;
                        hideAnnotation();
                    }
        
                    if(usePainting) {
                        artMatRef.current.uniforms.highlightAmt.value = highlightAmt.current;
                    }
                }
        
                else if(usePainting) {
                    artMatRef.current.uniforms.highlightAmt.value = 0.0;
                }
        
                //animate card to land pos
                let myPos = groupRef.current.position;
        
                //FALLING 
                if(myPos.y > landPos.current.y) {
                    //increase velocity by gravity
                    velocity.y -= 0.0025;
        
                    //clamp velocity
                    if(velocity.y < -0.1) {
                        velocity.y = -0.1;
                    }
        
                    //add velocity to position
                    myPos.y += velocity.y;
        
                    let distToLand = myPos.y - (landPos.current.y);
        
                    //calculate percentage of distance from start to end
                    let percent = 1.0 - (distToLand / fallDist);
        
                    let rotAng = (Math.PI * 2.0) * percent * 5.0;
        
                    //rotate on Z axis 
                    meshRef.current.rotation.z = rotAng + (Math.PI * 0.5);
                    meshRef.current.rotation.x = rotAng + (Math.PI * 0.5);
        
                    //clamp to land pos
                    if(myPos.y < (landPos.current.y)) {
                        myPos.y = (landPos.current.y);
                    }
        
                    groupRef.current.position.set(myPos.x, myPos.y, myPos.z);

                    //UPDATE CAM FOR RECORDING
                    if(paintingID == mintedWorkID.current && recording.current && paintingVisible) {
                        let aboveOffset = 3.0;

                        if(!didResetWork.current) {
                            resetArtwork();
                            didResetWork.current = true;
                            // console.log('RESETTING WORK');

                            //move camera to start position 
                            meshRef.current.getWorldPosition(worldLookPos);

                            controls.current.setPosition(worldLookPos.x, worldLookPos.y + aboveOffset, worldLookPos.z, false);
                            controls.current.setTarget(worldLookPos.x, worldLookPos.y, worldLookPos.z, false);                    
                            controls.current.setFocalOffset(0, 0, 0, false);    
                            controls.current.zoomTo(cardFallingZoom / 8.0, false);

                            controls.current.dampingFactor = 0.01;

                            controls.current.update();
                        }
                        else {
                            meshRef.current.getWorldPosition(worldLookPos);
            
                            //update damping factor to slow down move, but this won't work for setposition 
    
                            let camPos = state.camera.position;
                            controls.current.setFocalOffset(0, 0, 0, true);
    
                            if(percent < showMountainPercent) {
                                //ABOVE CARD
                                controls.current.setPosition(worldLookPos.x, worldLookPos.y + aboveOffset, worldLookPos.z, true);
                                controls.current.setTarget(worldLookPos.x, worldLookPos.y, worldLookPos.z, true);                    

                                controls.current.zoomTo(cardFallingZoom, true);            
                            }
    
                            else {
                                //SIDE CARD
                                let sideViewAboveOffset = 5.0;

                                let rotAng = (((percent - showMountainPercent) / (1.0 - showMountainPercent)) * Math.PI / 2.0);
                                let offsetX = Math.cos(rotAng);
                                let offsetZ = Math.sin(rotAng);

                                let sideOffset = new THREE.Vector3(offsetX, 0, offsetZ);
                                sideOffset.multiplyScalar(mountainOffsetDist);
    
                                //LOOK AT MOUNTAIN
                                controls.current.setTarget(0, 0, 0, true);                    

                                controls.current.setPosition(worldLookPos.x + sideOffset.x, worldLookPos.y + sideViewAboveOffset, worldLookPos.z + sideOffset.z, true);
                
                                controls.current.zoomTo(mountainZoom, true);            
                            }    
                        }
                    }
                    else if(didResetWork.current) {
                        didResetWork.current = false;
                    }
                }
        
                //FELL
                else {
                    let noiseOffsetAng = perlin.noise(pos.x * swayNoiseScale, pos.y * swayNoiseScale, pos.z * swayNoiseScale) * Math.PI * 2.0;
        
                    let sway = Math.sin(state.clock.elapsedTime * swaySpeed + noiseOffsetAng) * swayAmplitude;
                    let curRotZ = groupRef.current.rotation.z;
        
                    //SLIDE
                    if(slide.current < 1.0) {
                        slide.current += 0.01;
        
                        let slideAmt = slide.current * slideDist;
        
                        let newY = (landPos.current.y) - slideAmt;
        
                        groupRef.current.position.set(myPos.x, newY, myPos.z);
                        groupRef.current.rotation.z = lerp(curRotZ, sway, 0.025);                    
                    }
        
                    //SWAY
                    else {    
                        groupRef.current.rotation.z = lerp(curRotZ, sway, 0.1);                    
                    }

                    //UPDATE CAM FOR RECORDING
                    if(paintingID == mintedWorkID.current && recording.current && paintingVisible) {

        
                        meshRef.current.getWorldPosition(worldLookPos);
            
                        let camPos = state.camera.position;

                        controls.current.setTarget(worldLookPos.x, worldLookPos.y, worldLookPos.z, true);
                        controls.current.setFocalOffset(0, 0, 0, true);
            
                        let offsetAmt = 6.0;

                        //get vector pointing in front of mesh
                        let lookVec = new THREE.Vector3(0, 1, 0);
                        lookVec.applyQuaternion(meshRef.current.quaternion);

                        controls.current.setPosition(worldLookPos.x + lookVec.x, worldLookPos.y + lookVec.y, worldLookPos.z + lookVec.z, true);
                        controls.current.zoomTo(6.0, true);            
                    }
                    else if(didResetWork.current) {
                        didResetWork.current = false;
                    }
                }        
            }

        }
        else if (paintingID == mintedWorkID.current && recording.current) {
            //drop painting if recorded
            ref.current[paintingID] = true;
        }
        else {
            if(!didResetWork.current && groupRef.current) {                
                didResetWork.current = true;
                resetArtwork();
                // setPaintingVisible(false);
            }
        }
    });

    function showPaintingPreview() {
            //change the preview hover image
            let paintingFile = process.env.PUBLIC_URL + "/textures/paintings_med/" +  (paintingID + 1).toString() + ".jpg";
            previewHover.current.children[0].src = paintingFile;

            //add the visible class to the preview hover
            previewHover.current.classList.add('visible');

            previewPainting.current = paintingID;

            //change text of previewHover to 'minted by' + ownerText
            previewHover.current.children[1].innerHTML = 'minted by ' + ownerText;

            //change highlightCol to purple
            artMatRef.current.uniforms.highlightCol.value = new THREE.Vector3(0.5, 0.0, 0.5);
    }

    function hidePaintingPreview() {
        setTimeout(() => {
            if(previewPainting.current == paintingID) {
                previewHover.current.classList.remove('visible');
            }
    
        }, 3000);

        artMatRef.current.uniforms.highlightCol.value = new THREE.Vector3(1.0, 1.0, 1.0);
    }

    function showArtAnnotation() {
        if(!annotationVisible.current) {
            annotationVisible.current = true;
            showAnnotation();
            annotationAge.current = 0;
        }
    }

    return(
            paintingVisible && (<group ref={groupRef} dispose={null}>
                <mesh 
                ref={meshRef}
                geometry={geo}
                onPointerOver={() => {
                    if(previewHover) {
                        showPaintingPreview();
                    }
                    showArtAnnotation();
                }} 
                onPointerLeave={() => {
                    if(previewHover) {
                        hidePaintingPreview();
                    }
                }}
                onPointerDown={() => {
                    if(previewHover) {
                        showPaintingPreview();
                    }
                    showArtAnnotation();
                }}
                // geometry={geo} 
                castShadow={true}
                rotation={rot} 
                position={[0,pivotYOffset,0]} 
                renderOrder={100}>
                                            
                {/* QUESTION MARK MAT */}
                {!usePainting && <QuestionMarkMat />} 

                {/* TODO PUT IN OWN COMPONENT */}
                {usePainting && <CustomShaderMaterial
                    ref={artMatRef}
                    baseMaterial={THREE.MeshBasicMaterial}
                    vertexShader={
                        `
                        varying vec2 myUv;

                        void main() {
                            myUv = uv;
                        }                        
                        `
                    }
                    fragmentShader={
                    ` 
                    uniform float highlightAmt;
                    uniform float paintingSat;
                    uniform vec3 highlightCol;
                    varying vec2 myUv;

                    uniform sampler2D paintingTex;

                    vec3 czm_saturation(vec3 rgb, float adjustment)
                    {
                        // Algorithm from Chapter 16 of OpenGL Shading Language
                        const vec3 W = vec3(0.2125, 0.7154, 0.0721);
                        vec3 intensity = vec3(dot(rgb, W));
                        return mix(intensity, rgb, adjustment);
                    }            
                    
                    void main() {
                        //highlight edges of art using uvs 

                        float edge = 0.0;
                        float edgeWidthY = 0.1;
                        float edgeWidthX = 0.2;

                        float minEdgeX = min(myUv.x, 1.0 - myUv.x);
                        float minEdgeY = min(myUv.y, 1.0 - myUv.y);

                        if(minEdgeY < edgeWidthY) {
                            edge = 1.0 - (minEdgeY / edgeWidthY);
                        }

                        if(minEdgeX < edgeWidthX) {
                            edge = 1.0 - (minEdgeX / edgeWidthX);
                        }

                        //flip y uv
                        vec2 myUvFlipped = vec2(myUv.x, 1.0 - myUv.y);

                        vec3 paintingCol = texture2D(paintingTex, myUvFlipped).rgb;
                        paintingCol = czm_saturation(paintingCol, paintingSat);

                        vec3 color = mix(paintingCol, highlightCol, edge * highlightAmt * 0.7);
                        csm_FragColor = vec4(color, 1.0);

                        // if(myUv.x < edgeWidthX || myUv.x > 1.0 - edgeWidthX) {
                        //     edge = 1.0;
                        // }

                        // if(myUv.y < edgeWidthY || myUv.y > 1.0 - edgeWidthY) {
                        //     edge = 1.0;
                        // }

                        // vec3 highlight = vec3(1.0);

                        // vec3 color = mix(csm_DiffuseColor.rgb, highlight, edge * highlightAmt * 0.7);

                        // csm_FragColor = vec4(color, 1.0);
                    }
                    `
                    }
                    uniforms={{
                        highlightAmt: {
                            value: 1.0,
                        },
                        highlightCol: {
                            value: new THREE.Vector3(1.0, 1.0, 1.0)
                        },
                        paintingSat: {
                            value: paintingSat
                        },
                        paintingTex: {
                            value: paintingTex.current
                        }

                    }}
                    color={0xffffff}
                    transparent={true}
                    // map={tex}
                    // map-flipY={false}
                    castShadow={true}
                    side={THREE.DoubleSide}
                    // ...
                />}

                <Html 
                    ref={annotationRef}
                    zIndexRange={[0, 5]}
                    center
                    style={{
                        color: textCol,
                        fontFamily: 'Duke',
                        fontSize: '0.8rem',
                        fontWeight: 'normal',
                        pointerEvents: 'none',
                        textShadow: '0px 0px 5px rgba(255,255,255,1.0), 0px 0px 10px rgba(255,255,255,1.0), 0px 0px 15px rgba(255,255,255,1.0)',
                        transform: "translate(-50%, "+ randTextOffset + ")",
                        WebkitTouchCallout: "none", 
                        WebkitUserSelect: "none", 
                        KhtmlUserSelect: "none",
                        MozUserSelect: "none", 
                        MsUserSelect: "none", 
                        UserSelect: "none" 
                    }}>
                        {ownerText}
                </Html>
                </mesh>
            </group>)
    );
});

function Scene({mintedWorks, heartParticlesActive, skysphereActive, ssaoActive, bloomActive, godRaysActive, CanvasCapture, playTimeline}) {

    const { camY, camZ, camZoom, myFov } = useControls('camera settings',{
        camZ: {
            value: -4,
            min: -10,
            max: 10,
            step: 1,
          },
          camY: {
            value: -1,
            min: -10,
            max: 10,
            step: 1,
          },
          camZoom: {
            value: 1.0,
            min: 0.1,
            max: 100,
            step: 0.1,
          },
          myFov: {
            value: 75,
            min: 0.0,
            max: 180,
            step: 1,
          },
          
    });

    const { capeFlapAmt, capeFlapSpeed } = useControls('cape', {
        capeFlapAmt: {
            value: 0.1,
            min: 0.0,
            max: 0.5,
            step: 0.01,
          },
          capeFlapSpeed: {
            value: 5.0,
            min: 0.0,
            max: 50.0,
            step: 0.1,
          }
    });

    const {
        capeTextCol, 
        capePlasmaMax, 
        capePlasmaSpeed, 
        capePlasmaTiling, 
        capePlasmaPow, 
        capePlasmaHueShift, 
        capePlasmaBoost, 
        capePlasmaSat, 
        capePlasmaOpacity, 
        capeBaseRoughness, 
        capeBaseMetalness, 
        capeNormalStrength,
        capeNormalTiling,
        capeEnvMapIntensity
    } = useControls('cape material', {
        capeTextCol: '#efde8f',
        capePlasmaMax: {
            value: 0.3,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        capePlasmaSpeed: {
            value: 1.5,
            min: 1.0,
            max: 10.0,
            step: 0.01,
        },
        capePlasmaTiling: {
            value: 1000.0,
            min: 100.0,
            max: 2000.0,
            step: 0.1,
        },
        capePlasmaPow: {
            value: 4.0,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        capePlasmaHueShift: {
            value: 0.0,
            min: 0.0,
            max: Math.PI * 2.0,
            step: 0.01,
        },
        capePlasmaBoost: {
            value: 1.0,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        capePlasmaSat: {
            value: 4.0,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        capePlasmaOpacity: {
            value: 0.8,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        capeBaseRoughness: {
            value: 0.1,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        capeBaseMetalness: {
            value: 0.7,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        capeNormalStrength: {
            value: 2.0,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        capeNormalTiling: {
            value: 4.0,
            min: 0.0,
            max: 30.0,
            step: 0.01,
        },
        capeEnvMapIntensity: {
            value: 3.0,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        }
    });

    const { tearHaloCol, tearRoughness, tearFresnelBoost, tearTransmission, tearIOR, tearClearcoat, tearAnimFrames, tearGrowFrames, tearSize, tearAccel, tearEnvMapIntensity } = useControls('tear', {
        tearHaloCol: '#0bbfde',
        tearFresnelBoost: {
            value: 15.0,
            min: 0.0,
            max: 50.0,
            step: 0.01,
        },
        tearRoughness: {
            value: 0.0,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        tearTransmission: {
            value: 0.5,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        tearIOR: {
            value: 1.3,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        tearClearcoat: {
            value: 1.0,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        tearAnimFrames: {
            value: 150,
            min: 0,
            max: 1000,
            step: 1,
        },
        tearGrowFrames: {
            value: 80,
            min: 0,
            max: 1000,
            step: 1,
        },
        tearSize: {
            value: 2.0,
            min: 0.5,
            max: 3.0,
            step: 0.01,
        },
        tearAccel: {
            value: 0.01,
            min: 0.001,
            max: 0.1,
            step: 0.01,
        },
        tearEnvMapIntensity: {
            value: 10.0,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
    });

    const { 
        toneMappingExposure,
        hue,
        saturation,
        brightness,
        contrast,
        AOintensity, 
        AOBias, 
        AOradius, 
        AODistThresh, 
        AODistFallOff, 
        BloomThreshold, 
        BloomSmoothing, 
        BloomHeight, 
        BloomIntensity 
    } = useControls('lighting',{
        toneMappingExposure: {
            value: 1.0,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        hue: {
            value: 0.0,
            min: 0.0,
            max: Math.PI * 2.0,
            step: 0.01,
        },
        saturation: {
            value: 0.3,
            min: 0.0,
            max: Math.PI * 2.0,
            step: 0.01,
        },
        brightness: {
            value: 0.0,
            min: -1.0,
            max: 1.0,
            step: 0.01,
        },
        contrast: {
            value: 0.0,
            min: -1.0,
            max: 1.0,
            step: 0.01,
        },
        AOintensity: {
            value: 8.6,
            min: 0.0,
            max: 20.0,
            step: 0.1,
        },  
        AOBias: {
            value: 0.1,
            min: 0,
            max: 1,
            step: 0.01,
        },
        AOradius: {
            value: 0.13,
            min: 0,
            max: 1,
            step: 0.01,
        },
        AODistThresh: {
            value: 10.0,
            min: 0,
            max: 100.0,
            step: 0.01,
        },
        AODistFallOff: {
            value: 0.34,
            min: 0.0,
            max: 100.0,
            step: 0.01,
        },
        BloomThreshold: {
            value: 0.4,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        BloomSmoothing: {
            value: 0.9,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        BloomHeight: {
            value: 300,
            min: 0,
            max: 1000,
            step: 1,
        },
        BloomIntensity: {
            value: 1.5,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
    });

    const undulationStrengthInit = 0.12;
    const undulationScaleInit = 1.00;

    const {samples, density, decay, weight, exposure, animStrength, animScale } = useControls('heart godrays', {
        samples: {
            value: 10,
            min: 0,
            max: 1000,
            step: 1,
        },
        density: {
            value: 0.9,
            min: 0,
            max: 1,
            step: 0.01,
        },
        decay: {
            value: 0.9,
            min: 0,
            max: 1,
            step: 0.01,
        },
        weight: {
            value: 0.4,
            min: 0,
            max: 1,
            step: 0.01,
        },
        exposure: {
            value: 0.3,
            min: 0,
            max: 1,
            step: 0.01,
        },
        animStrength: {
            value: undulationStrengthInit,
            min: -3.0,
            max: 3.0,
            step: 0.01,
        },
        animScale: {
            value: undulationScaleInit,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
    });

    const {
        engorgeTilingSpeed,
        plasmaTilingSpeed,
        emissiveDarkenSpeed,
        fresnelBoostSpeed,
        fresnelPowSpeed,
        solidAnimStrength, 
        solidAnimScale, 
        animSpeed, 
        heartTransmission, 
        // plasmaTiling, 
        heartRoughness, 
        heartMetalness, 
        heartClearcoat, 
        heartSheen, 
        heartNormalStrength, 
        heartEnvMapIntensity, 
        heartEmissiveIntensity,
        normalMapScale,
        // plasmaEmissiveDarken,
        plasmaStrength,
        plasmaMaxCol,
        plasmaHueShift,
        heartMatAnimSpeed,
        // fresnelBoost,
        // fresnelPow,
        heartScalePulse,
        pulseSpeed,
        heartScaleLerp,
        engorgeCol,
        engorgePlasmaAmt,
        // engorgeTilingFactor
    } = useControls('heart solid', {
        engorgeCol: '#ff0069',

        engorgeTilingSpeed: {
            value: 0.1,
            min: 0.0,
            max: 5.0,
            step: 0.01,
        },
        plasmaTilingSpeed: {
            value: 0.2,
            min: 0.0,
            max: 5.0,
            step: 0.01,
        },
        emissiveDarkenSpeed: {
          value: 0.5,
            min: 0.0,
            max: 5.0,
            step: 0.01,
        },
        fresnelBoostSpeed: {
            value: 1.0,
            min: 0.0,
            max: 5.0,
            step: 0.01,
        },
        fresnelPowSpeed: {
            value: 0.25,
            min: 0.0,
            max: 5.0,
            step: 0.01,
        },
        // engorgeTilingFactor: {
        //     value: 2.0,
        //     min: 0.0,
        //     max: 10.0,
        //     step: 0.01,
        // },
        engorgePlasmaAmt: {
            value: 0.8,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        plasmaHueShift: {
            value: 0.82,
            min: 0.0,
            max: Math.PI * 2.0,
            step: 0.01,
        },
        // plasmaTiling: {
        //     value: 460.0,
        //     min: 100.0,
        //     max: 2000.0,
        // },
        plasmaStrength: {
            value: 1.0,
            min: 0.0,
            max: 1.0,
        },
        plasmaMaxCol: {
            value: 0.47,
            min: 0.0,
            max: 1.0,
        },
        heartRoughness: {
            value: 0.0,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        heartMetalness: {
            value: 0.87,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        heartEnvMapIntensity: {
            value: 9,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        // plasmaEmissiveDarken: {
        //     value: 2.84,
        //     min: 0.0,
        //     max: 5.0,
        //     step: 0.01,
        // },
        // fresnelPow: {
        //     value: 4.61,
        //     min: 0.0,
        //     max: 10.0,
        //     step: 0.01,
        // },
        // fresnelBoost: {
        //     value: 1.0,
        //     min: 0.0,
        //     max: 10.0,
        //     step: 0.01,
        // },
        solidAnimStrength: {
            value: 0.68,
            min: -3.0,
            max: 3.0,
            step: 0.01,
        },
        solidAnimScale: {
            value: 1.35,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        animSpeed: {
            value: 4.86,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        heartClearcoat: {
            value: 1.0,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        heartSheen: {
            value: 0.84,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        heartNormalStrength: {
            value: 4.96,
            min: 0.0,
            max: 5.0,
            step: 0.01,
        },
        heartEmissiveIntensity: {
            value: 2.0,
            min: 0.0,
            max: 10.0,
            step: 0.01,
        },
        normalMapScale: {
            value: 8.76,
            min: 0.5,
            max: 10.0,
            step: 0.01,
        },
        // grungeMapScale: {
        //     value: 0.75,
        //     min: 0.1,
        //     max: 10.0,
        //     step: 0.01,
        // },
        heartMatAnimSpeed: {
            value: 0.8,
            min: 0.5,
            max: 2.0,
            step: 0.01,
        },
        heartScalePulse: {
            value: 0.2,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        heartScaleLerp: {
            value: 0.1,
            min: 0.0,
            max: 1.0,
            step: 0.01,
        },
        pulseSpeed: {
            value: 20.0,
            min: 0.0,
            max: 50.0,
            step: 0.01,
        },
    });
    
    const [sunRef, setSunRef] = useState();
    const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + '/models/mountain_11_4_comp.glb')
    const godRaysSun = useGLTF(process.env.PUBLIC_URL + '/models/sun.glb')
    const skyTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/skysphere_4k.jpg");

    // const artTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/jared_111.jpg");

    // const mud_basecolor = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mud_basecolor.jpg");
    // const mud_normal = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mudmat_Normal.jpg");

    // const dadBaseTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mud_basecolor.jpg");
    // const dadNormalTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mudmat_Normal.jpg");

    // const kidBaseTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mud_basecolor.jpg");
    // const kidNormalTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mudmat_Normal.jpg");

    // const mountainBaseTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mud_basecolor.jpg");
    // const mountainNormalTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/mud/mudmat_Normal.jpg");

    const heartGodrayTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/matcap/Jade_Red1a.png");

    const heartMatcap = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/matcap/flesh.png");
    const heartNormal = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/vn_4.jpg");
    // const heartGrunge = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/grunge.jpg");
    const heartPlasma = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/plasma.jpg");
    const particlesMatcap = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/matcap/cranked.jpg");

    const figureScale = 0.2;

    const capeTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/cape_tex.jpg");
    const capeMask = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/cape_mask.jpg");

    const capeNormal = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/cape_normal.jpg");
    const capeRoughness = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + "/textures/cape_roughness.jpg");

    const capeMatRef = useRef();

    const heartMatRef = useRef();
    const heartRef = useRef();

    const materialState = useRef(0.0);
    const heartScale = useRef(1.0);

    const tearRef = useRef();

    const tearCount = useRef(0);
    const tearYInitial = useRef(0);
    const tearVel = useRef(0);

    const recordingDPR = 1.0;
    const normalDPR = isMobile ? 1.0 : 0.8;

    const { recording, panUp, panDown, panLeft, panRight, controls, canRecord } = useContext(SceneContext);

    // const lastDroppedTime = useRef(-1);
    // const [artworkCards, setArtworkCards] = useState([]);
    // const artworkFell = useRef([]);

    useThree(({ gl, camera, scene }) => {
        gl.toneMappingExposure = toneMappingExposure;
    });

    useEffect(() => {
        console.log('init recording');

        //get first canvas element that's a child of the canvas parent
        let canvas = document.getElementsByTagName('canvas')[0];
        console.log(canvas);

        if(!isMobile && CanvasCapture) {
            CanvasCapture.init(
                canvas,
                { 
                    showRecDot: true,
                    showAlerts: true,
                    showDialogs: true,
                    verbose: false,             
                }, // Options are optional, more info below.
            );            
        }
    }, []);

    useEffect(() => {
        const nodes = godRaysSun.nodes;
        console.log(nodes);
        
        let sun = new THREE.Mesh(
            nodes.sun.geometry,
            new THREE.ShaderMaterial({
                vertexShader: /* glsl */ `
                    float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
                    vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
                    vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}
        
                    float noise(vec3 p){
                        vec3 a = floor(p);
                        vec3 d = p - a;
                        d = d * d * (3.0 - 2.0 * d);
        
                        vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
                        vec4 k1 = perm(b.xyxy);
                        vec4 k2 = perm(k1.xyxy + b.zzww);
        
                        vec4 c = k2 + a.zzzz;
                        vec4 k3 = perm(c);
                        vec4 k4 = perm(c + 1.0);
        
                        vec4 o1 = fract(k3 * (1.0 / 41.0));
                        vec4 o2 = fract(k4 * (1.0 / 41.0));
        
                        vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
                        vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);
        
                        return o4.y * d.y + o4.x * (1.0 - d.y);
                    }
        
                    varying vec2 vUv;
                    uniform float uTime;
                    uniform float strength;
                    uniform float scale;

                    void main() {
                        vUv = uv;
                        // gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

                        vec3 pos = position;

                        vec3 timeOffset = vec3(0., 0., uTime);
                        pos += normal * noise(position * scale + timeOffset) * strength;

                        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
                    }
                `,
                fragmentShader: /* glsl */ `
                    varying vec2 vUv;
                    uniform sampler2D map;
                    
                    void main() {
                        //get uv with tiling 2

                        vec2 uv = vUv;
                        uv *= 2.0;
                        uv = fract(uv);

                        vec4 tex = texture2D(map, uv);
                        gl_FragColor = tex;
                    }
                `,
                // depthWrite: false,
                // depthTest: false,
                uniforms: {
                    //TODO - change godrays texture 
                    map: { value: heartGodrayTex },
                    uTime: { value: 0.0 },
                    strength: { value: undulationStrengthInit },
                    scale: { value: undulationScaleInit },
                },
            })
        );

        sun.name = "godRaysMesh";

        sun.position.set(0.357815, 1.54294, -1.57104);
        sun.updateMatrixWorld();

        console.log(sun);

        setSunRef(sun);

    }, [godRaysSun]);
    
    useEffect(() => {
        console.log(nodes);
        tearYInitial.current = nodes.tear.position.y;
    }, [nodes]);

    useEffect(() => {
        heartNormal.wrapS = heartNormal.wrapT = THREE.RepeatWrapping;
        heartNormal.repeat.set( 8, 4 );
    }, [heartNormal]);

    useEffect(() => {
        capeNormal.wrapS = capeNormal.wrapT = THREE.RepeatWrapping;
        capeNormal.repeat.set( 30, 30 );
    }, [capeNormal]);

    useEffect(() => {
        capeRoughness.wrapS = capeRoughness.wrapT = THREE.RepeatWrapping;
        capeRoughness.repeat.set( 3, 3 );
    }, [capeRoughness]);

    useFrame((state, delta) => {

        //HANDLE PANNING 
        let panAmt = 0.1;

        if(panUp.current == true) {
            controls.current.truck(0.0, -panAmt, true);
        }
        if(panDown.current == true) {
            controls.current.truck(0.0, panAmt, true);
        }
        if(panLeft.current == true) {
            controls.current.truck(-panAmt, 0.0, true);
        }
        if(panRight.current == true) {
            controls.current.truck(panAmt, 0.0, true);
        }

        //RECORD FRAMES IF RECORDING IS ACTIVE
        if(!isMobile && recording.current && CanvasCapture.isRecording()) {
            if(state.viewport.dpr != recordingDPR) {
                state.setDpr(recordingDPR);
            }
            
            CanvasCapture.recordFrame();
        }

        else if(state.viewport.dpr != normalDPR) {
            state.setDpr(normalDPR);
        }

        //UPDATE TIME UNIFORM IN SUN REF
        if(sunRef) {
            sunRef.material.uniforms.uTime.value += delta * animSpeed;
            sunRef.material.uniforms.strength.value = animStrength;
            sunRef.material.uniforms.scale.value = animScale;
        }

        if(heartRef.current && materialState.current && heartScale.current && sunRef) {
            let expand = (1.0 - materialState.current) > 0.6;

            let heartScaleTarget = expand ? 2.0 : 1.0;
            
            heartScaleTarget += Math.sin(state.clock.elapsedTime * pulseSpeed) * Math.random() * heartScalePulse;

            heartScale.current = lerp(heartScale.current, heartScaleTarget, heartScaleLerp);

            //randomly scale heart between 0.9 and 2.0
            heartRef.current.scale.setScalar(heartScale.current);

            sunRef.scale.setScalar(heartScale.current);
            sunRef.updateMatrixWorld();
        }

        if(heartMatRef.current) {
            // material state 0 = velvet, 1 = glass

            materialState.current = Math.sin(state.clock.elapsedTime * heartMatAnimSpeed) * 0.5 + 0.5;
            materialState.current = clamp(materialState.current, 0.0, 1.0);

            heartMatRef.current.transmission = materialState.current;
            heartMatRef.current.metalness = (1.0 - materialState.current) * heartMetalness;

            heartMatRef.current.uniforms.uTime.value += delta * animSpeed;

            heartMatRef.current.uniforms.materialState.value = materialState.current; 
            
            heartMatRef.current.uniforms.normalMapStrength.value = (1.0 - materialState.current);

            //OSCILLATE ENGORGE TILING BETWEEN 1.13 AND 10
            // let engorgeTilingSpeed = 0.5;
            heartMatRef.current.uniforms.engorgeTilingFactor.value = mapLinear(Math.sin(state.clock.elapsedTime * engorgeTilingSpeed), -1.0, 1.0, 1.13, 10.0);

            //OSCILLATE PLASMA TILING BETWEEN 460 AND 1740
            // let plasmaTilingSpeed = 0.5;
            heartMatRef.current.uniforms.plasmaTiling.value = mapLinear(Math.sin(state.clock.elapsedTime * plasmaTilingSpeed), -1.0, 1.0, 460.0, 1740.0);

            //OSCILLATE EMISSIVE DARKEN BETWEEN 0.13 AND 4.39
            // let emissiveDarkenSpeed = 0.5;
            heartMatRef.current.uniforms.emissiveDarken.value = mapLinear(Math.sin(state.clock.elapsedTime * emissiveDarkenSpeed), -1.0, 1.0, 0.13, 4.39);

            //OSCILLATE FRESNEL BOOST + FRESNEL POW  BETWEEN 0.1 AND 9.9
            // let fresnelBoostSpeed = 0.5;
            heartMatRef.current.uniforms.fresnelBoost.value = mapLinear(Math.sin(state.clock.elapsedTime * fresnelBoostSpeed), -1.0, 1.0, 0.1, 9.9);

            // let fresnelPowSpeed = 0.5;
            heartMatRef.current.uniforms.fresnelPow.value = mapLinear(Math.sin(state.clock.elapsedTime * fresnelPowSpeed), -1.0, 1.0, 0.1, 9.9);
        }

        state.camera.fov = myFov;
        //state.camera.position.z = camZ;
        //state.camera.position.y = camY;
        //state.camera.zoom = camZoom;
        //state.camera.updateProjectionMatrix();
        //console.log(state.camera.fov);

        if(capeMatRef.current) {
            capeMatRef.current.uniforms.uTime.value += delta * capeFlapSpeed;
        }

        if(tearRef.current) {
            //increment tearCount
            tearCount.current += 1;
            let curFrame = tearCount.current % tearAnimFrames;

            let pct = curFrame / tearAnimFrames;

            if(curFrame < tearGrowFrames) {
                pct = curFrame / tearGrowFrames;
                tearRef.current.scale.set(pct*tearSize, pct*tearSize, pct*tearSize);
                tearRef.current.position.y = tearYInitial.current;
                tearVel.current = 0;
            }

            else {
                //make tear fall down
                //let fallPct = (curFrame - tearGrowFrames) / (tearAnimFrames - tearGrowFrames);
                let curPos = tearRef.current.position.y;
                //let newPos = tearYInitial.current - fallPct * 15;

                tearVel.current += tearAccel;
                let newPos = curPos - tearVel.current;
                tearRef.current.position.y = newPos;
            }
        }
    });

    return (
        <>
        <PixarClouds />

        <group scale={[figureScale, figureScale, figureScale]} dispose={null}>

            {/* use conditional rendering to only mount timeline after mintedWorks is greater than 0 length */}
            {mintedWorks.length > 0 && <Timeline mintedWorks={mintedWorks} nodes={nodes} playTimeline={playTimeline}/>}

            <mesh geometry={nodes.dad_hair.geometry} receiveShadow={true} renderOrder={100}>
                <MudMat />
            </mesh>
            <mesh geometry={nodes.figure_dad.geometry} receiveShadow={true} renderOrder={100}>
                <MudMat />
            </mesh>
            <mesh geometry={nodes.figure_kid.geometry} receiveShadow={true} renderOrder={100}>
                <MudMat />
            </mesh>

            <mesh position={nodes.heart.position}>
                {heartParticlesActive && <HeartParticles count={30} heartMatAnimSpeed={heartMatAnimSpeed} matcapTex={particlesMatcap}/>}
            </mesh>
            
            <mesh geometry={nodes.heart.geometry} ref={heartRef} position={nodes.heart.position} renderOrder={2000}>
                {/* <MudMat /> */}

                {/* CUSTOM HEART MAT */}
                <CustomShaderMaterial 
                    ref={heartMatRef}
                    baseMaterial={THREE.MeshPhysicalMaterial}
                    vertexShader={
                        /* glsl */ 
                        `
                        float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
                        vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
                        vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}
            
                        float noise(vec3 p){
                            vec3 a = floor(p);
                            vec3 d = p - a;
                            d = d * d * (3.0 - 2.0 * d);
            
                            vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
                            vec4 k1 = perm(b.xyxy);
                            vec4 k2 = perm(k1.xyxy + b.zzww);
            
                            vec4 c = k2 + a.zzzz;
                            vec4 k3 = perm(c);
                            vec4 k4 = perm(c + 1.0);
            
                            vec4 o1 = fract(k3 * (1.0 / 41.0));
                            vec4 o2 = fract(k4 * (1.0 / 41.0));
            
                            vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
                            vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);
            
                            return o4.y * d.y + o4.x * (1.0 - d.y);
                        }
                        
                        varying vec2 myUv;
                        uniform float uTime;
                        uniform float strength;
                        uniform float scale;

                        varying float dist;

                        varying vec3 vNorm;
                        varying vec3 vEye;

                        vec3 orthogonal(vec3 v) {
                            return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0) : vec3(0.0, -v.z, v.y));
                        }                          
    
                        void main() {
                            myUv = uv;
    
                            vec3 pos = position;
    
                            vec3 timeOffset = vec3(0., 0., uTime);
                            pos += normal * noise(position * scale + timeOffset) * strength;
    
                            csm_Position = pos.xyz;

                            
                            // float offset = 0.001;
                            // vec3 tangent = orthogonal(normal);
                            // vec3 bitangent = normalize(cross(normal, tangent));
                            // vec3 neighbour1 = position + tangent * offset;
                            // vec3 neighbour2 = position + bitangent * offset;
                          
                            // vec3 displaced1 = neighbour1 + normal * noise(neighbour1 * scale + timeOffset) * strength;
                            // vec3 displaced2 = neighbour2 + normal * noise(neighbour2 * scale + timeOffset) * strength;

                            // vec3 displacedTangent = displaced1 - pos.xyz;
                            // vec3 displacedBitangent = displaced2 - pos.xyz;
                            // csm_Normal = normalize(cross(displacedTangent, displacedBitangent));


                            //calculate camera distance from vertex
                            vec4 mvPos = modelViewMatrix * vec4( pos, 1.0 );

                            dist = length(mvPos.xyz);

                            vNorm = normalize(normalMatrix * normal);
                            vEye = normalize(vec3(modelViewMatrix * vec4(position, 1.0)).xyz);
                        }    
                        `
                    }
                    fragmentShader={/* glsl */ 
                    ` 
                    // uniform sampler2D grungeMap;
                    uniform sampler2D plasmaMap;
                    
                    varying vec2 myUv;
                    
                    uniform vec3 engorgeCol;

                    uniform float engorgePlasmaAmt;

                    uniform float engorgeTilingFactor;

                    uniform float materialState;

                    uniform float plasmaTiling;

                    uniform float plasmaHueShift;

                    varying float dist;

                    uniform float uTime;

                    uniform float plasmaMaxCol;
                    uniform float plasmaStrength;

                    uniform float normalMapScale;
                    uniform float normalMapStrength;

                    // uniform float grungeMapScale;

                    uniform float emissiveDarken;

                    uniform float fresnelBoost;
                    uniform float fresnelPow;

                    varying vec3 vNorm;
                    varying vec3 vEye;

                    vec3 csm_NormalMap;

                    float rand(float n){return fract(sin(n) * 43758.5453123);}

                    float noise(float p){
                        float fl = floor(p);
                      float fc = fract(p);
                        return mix(rand(fl), rand(fl + 1.0), fc);
                    }
                        
                    float noise(vec2 n) {
                        const vec2 d = vec2(0.0, 1.0);
                      vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
                        return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
                    }                    

                    // vec2 calcMatcap(vec3 eye, vec3 normal) {
                    //     vec3 reflected = reflect(eye, normal);
                    //     float m = 2.8284271247461903 * sqrt( reflected.z+1.0 );
                    //     return reflected.xy / m + 0.5;
                    // }

                    float blendOverlay(float base, float blend) {
                        return base<0.5?(2.0*base*blend):(1.0-2.0*(1.0-base)*(1.0-blend));
                    }
                    
                    vec3 blendOverlay(vec3 base, vec3 blend) {
                        return vec3(blendOverlay(base.r,blend.r),blendOverlay(base.g,blend.g),blendOverlay(base.b,blend.b));
                    }
                    
                    vec3 blendOverlay(vec3 base, vec3 blend, float opacity) {
                        return (blendOverlay(base, blend) * opacity + base * (1.0 - opacity));
                    }                    

                    vec3 hueShift( vec3 color, float hueAdjust ){

                        const vec3  kRGBToYPrime = vec3 (0.299, 0.587, 0.114);
                        const vec3  kRGBToI      = vec3 (0.596, -0.275, -0.321);
                        const vec3  kRGBToQ      = vec3 (0.212, -0.523, 0.311);
                    
                        const vec3  kYIQToR     = vec3 (1.0, 0.956, 0.621);
                        const vec3  kYIQToG     = vec3 (1.0, -0.272, -0.647);
                        const vec3  kYIQToB     = vec3 (1.0, -1.107, 1.704);
                    
                        float   YPrime  = dot (color, kRGBToYPrime);
                        float   I       = dot (color, kRGBToI);
                        float   Q       = dot (color, kRGBToQ);
                        float   hue     = atan (Q, I);
                        float   chroma  = sqrt (I * I + Q * Q);
                    
                        hue += hueAdjust;
                    
                        Q = chroma * sin (hue);
                        I = chroma * cos (hue);
                    
                        vec3    yIQ   = vec3 (YPrime, I, Q);
                    
                        return vec3( dot (yIQ, kYIQToR), dot (yIQ, kYIQToG), dot (yIQ, kYIQToB) );
                    }               
                    
                    float map(float value, float min1, float max1, float min2, float max2) {
                        return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
                    }                      
                      
                    void main() {
                        //PLASMA 
                        float tilingEngorgeAmt = (1.0 - materialState);
                        float tilingEngorge = map(tilingEngorgeAmt, 0.0, 1.0, plasmaTiling, plasmaTiling*engorgeTilingFactor);

                        vec2 vp = vec2(tilingEngorge, tilingEngorge);
                        float t = uTime * 4.0;
                        vec2 p0 = (myUv - 0.5) * vp;
                        vec2 hvp = vp * 0.5;
                        vec2 p1d = vec2(cos( t / 98.0),  sin( t / 178.0)) * hvp - p0;
                        vec2 p2d = vec2(sin(-t / 124.0), cos(-t / 104.0)) * hvp - p0;
                        vec2 p3d = vec2(cos(-t / 165.0), cos( t / 45.0))  * hvp - p0;
                        float plasma = 0.5 + 0.5 * (
                            cos(length(p1d) / 30.0) +
                            cos(length(p2d) / 20.0) +
                            sin(length(p3d) / 25.0) * sin(p3d.x / 20.0) * sin(p3d.y / 15.0));


                        // MATERIAL STATE 0 = VELVET, 1 = GLASS
                                            
                        //PLASMA COLS
                        float fresnelTerm = -min(dot(vEye, normalize(vNorm) ), 0.0);

                        fresnelTerm = 1.0 - clamp(fresnelTerm * fresnelBoost, 0., 1.);
                        fresnelTerm = pow(fresnelTerm, fresnelPow);

                        vec3 baseCol = mix(engorgeCol, vec3(1.0), materialState);

                        vec2 plasmaUV = vec2(plasma * plasmaMaxCol, 0.5);
                        vec3 plasmaCol = texture2D(plasmaMap, plasmaUV).rgb;
                        plasmaCol = hueShift(plasmaCol, plasmaHueShift);

                        plasmaCol = mix(baseCol, plasmaCol, plasmaStrength);

                        csm_DiffuseColor = vec4(mix(baseCol, plasmaCol, fresnelTerm), 1.0);

                        //EMISSIVE
                        float plasmaDist = distance(plasmaCol, vec3(1.0)) * plasmaStrength;

                        vec3 emissiveCol = mix(baseCol, plasmaCol, materialState + (1.0-plasma) * engorgePlasmaAmt);

                        emissiveCol = mix(emissiveCol, vec3(0.0), plasmaDist * emissiveDarken);

                        csm_Emissive = mix(emissiveCol, vec3(0.0), plasma * plasmaStrength);


                        //NORMAL MAP
                        // float normalFlip = (materialState - 0.5) * 2.0;
                        vec2 uvTiled = myUv * normalMapScale;

                        // csm_NormalMap = texture2D( normalMap, uvTiled).xyz * 2.0 * abs(normalFlip) - 1.0;

                        csm_NormalMap = texture2D( normalMap, uvTiled).xyz * 2.0;
                    
                        //make csm_DiffuseColor.a fade out if dist is too low
                        // float minDist = 2.0;
                        // float maxDist = 3.0;
                        // float distPct = ((dist - minDist) / (maxDist - minDist));
                        // distPct = clamp(distPct, 0.0, 1.0);
                        // csm_DiffuseColor.a *= distPct;

                        //GRUNGE 
                        // vec2 uvTiled2 = myUv * grungeMapScale;

                        // float grunge = texture2D(grungeMap, uvTiled2).r;
                        // grunge = pow(grunge, 2.0);

                        //FRESNEL - OLD
                        //float fresnelEdge = -min(dot(vEye, normalize(vNorm) ), 0.0);
                        //fresnelEdge = clamp(1.0 - fresnelTerm, 0., 1.);
                        //csm_DiffuseColor.a *= fresnelEdge * fadeEdgeBoost;
                        //fresnelEdge = clamp(fresnelTerm, 0., 1.);
                        //fresnelEdge = pow(fresnelEdge, fresnelPow);

                        //csm_DiffuseColor = mix(csm_DiffuseColor, vec4(1.0), fresnelEdge * fadeEdgeBoost * grunge);
                        //csm_Emissive = mix(csm_Emissive, vec3(1.0), (1.0-fresnelEdge) * fadeEdgeBoost * grunge);
                    }
                    `
                    }
                    patchMap={{
                        csm_NormalMap: {
                          "#include <normal_fragment_maps>": glsl`
                            vec3 mapN = csm_NormalMap;
                            mapN.xy *= normalMapStrength;
                            normal = perturbNormal2Arb( - vViewPosition, normal, mapN, faceDirection );
                          `,
                        },
                    }}
                
                    uniforms={{
                        uTime: {
                            value: 0.0,
                        },
                        strength: {
                            value: solidAnimStrength,
                        },
                        scale: {
                            value: solidAnimScale,
                        },
                        fresnelPow: {
                            value: 0.0
                        },
                        materialState: {
                            value: 0.0,
                        },
                        engorgeCol: {
                            value: new THREE.Color(engorgeCol),
                        },
                        engorgePlasmaAmt: {
                            value: engorgePlasmaAmt,
                        },
                        plasmaHueShift: {
                            value: plasmaHueShift,
                        },
                        plasmaTiling: {
                            value: 0.0,
                        },
                        plasmaStrength: {
                            value: plasmaStrength,
                        },
                        plasmaMaxCol: {
                            value: plasmaMaxCol,
                        },
                        emissiveDarken: {
                            value: 0.0,
                        },
                        engorgeTilingFactor: {
                            value: 0.0,
                        },
                        normalMapScale: {
                            value: normalMapScale,
                        },
                        normalMapStrength: {
                            value: heartNormalStrength,
                        },
                        plasmaMap: {
                            value: heartPlasma,
                        },
                        fresnelBoost: {
                            value: 0.0,
                        },
                    }}

                    color={0xffffff}
                    roughness={heartRoughness} 
                    metalness={heartMetalness}
                    transmission={0.0} 
                    ior={1.1} 
                    clearcoat={heartClearcoat} 
                    sheen={heartSheen}
                    envMapIntensity={heartEnvMapIntensity}
                    emissiveIntensity={heartEmissiveIntensity}
                    normalMap={heartNormal}
                    // normalMapStrength={heartNormalStrength}
                    normalMap-encoding={THREE.LinearEncoding}

                    //depthWrite={false}
                    //depthTest={false}
                    transparent={true}
    
                    // matcap={ heartMatcap }
                    // opacity={0.5}
                    // map={heartTex}
                    // blending={THREE.AdditiveBlending}
                />
            </mesh>            

            <mesh geometry={nodes.bat.geometry} renderOrder={100}>
                {/*<meshStandardMaterial map={nodes.bat.material.map} />*/}
                <MudMat />
            </mesh>

            {skysphereActive && (
            <mesh geometry={nodes.skysphere.geometry}>
                <meshBasicMaterial map={skyTex} fog={false} map-flipY={false}/>
            </mesh>
            )}

            <mesh ref={tearRef} geometry={nodes.tear.geometry} position={nodes.tear.position}>

                {/* custom tear mat */}
                <CustomShaderMaterial
                    baseMaterial={THREE.MeshPhysicalMaterial}
                    vertexShader={glsl`
                    varying vec3 vEye;
                    varying vec3 vNorm;

                    void main() {
                        vNorm = normalize(normalMatrix * normal);
                        vEye = normalize(vec3(modelViewMatrix * vec4(position, 1.0)).xyz);        
                    }
                    `}
                    fragmentShader={glsl`
                    varying vec3 vEye;
                    varying vec3 vNorm;

                    uniform vec3 haloCol;

                    uniform float tearFresnelBoost;

                    void main() {
                        float fresnelTerm = -min(dot(vEye, normalize(vNorm) ), 0.0);
                        fresnelTerm = 1.0 - clamp(fresnelTerm, 0., 1.);
                        fresnelTerm = pow(fresnelTerm, 1.2) * tearFresnelBoost;
                        fresnelTerm = clamp(fresnelTerm, 0., 1.);
                        
                        vec3 outCol = mix(csm_DiffuseColor.rgb, haloCol, fresnelTerm);

                        csm_DiffuseColor = vec4(outCol, 1.0);
                        csm_Emissive = mix(vec3(0.0), haloCol, fresnelTerm);
                    }
                    `}

                    uniforms={{
                        tearFresnelBoost: {
                            value: tearFresnelBoost
                        },
                        haloCol: {
                            value: new THREE.Color(tearHaloCol)
                        }
                    }}

                    roughness={tearRoughness} 
                    transmission={tearTransmission} 
                    ior={tearIOR} 
                    clearcoat={tearClearcoat} 
                    envMapIntensity={tearEnvMapIntensity}
                    color="#ffffff"     
                    />
            </mesh>
            <mesh geometry={nodes.mountain.geometry} receiveShadow={true} renderOrder={100}>
                <MudMat />
            </mesh>
            {/* <mesh geometry={nodes.clouds.geometry}>           
                <PixarCloudMat />     
            </mesh> */}

            <mesh geometry={nodes.cape.geometry} renderOrder={2000}>
                {/* CUSTOM CAPE MAT */}
                <CustomShaderMaterial
                    ref={capeMatRef}
                    baseMaterial={THREE.MeshPhysicalMaterial}
                    vertexShader={
                        /* glsl */ 
                        ` 
                        uniform float uTime;
                        uniform float capeFlapAmt;

                        varying vec2 myUV;

                        void main() {
                          vec3 pos = position;

                          float displaceStrength = uv.y * 2.0;

                          //displace pos along csm_Normal
                          pos += normal * sin(uTime*2.0 + position.x + position.y + position.z) * capeFlapAmt * displaceStrength;
                          
                          csm_Position = pos;

                          myUV = uv;
                        }                        
                        `
                    }
                    fragmentShader={/* glsl */ 
                    ` 
                    uniform sampler2D capeMask;
                    uniform sampler2D capeRoughness;
                    uniform sampler2D plasmaMap;
                    
                    uniform float uTime;
                    
                    uniform vec3 textCol;
                    uniform float plasmaMax;
                    uniform float plasmaPow;
                    uniform float plasmaTiling;
                    uniform float plasmaSpeed;
                    uniform float plasmaHueShift;
                    uniform float plasmaBoost;
                    uniform float plasmaSat;
                    uniform float plasmaOpacity;

                    uniform float baseRoughness;
                    uniform float baseMetalness;

                    uniform float baseNormalStrength;

                    uniform float normalTiling;

                    varying vec2 myUV;

                    vec3 csm_NormalMap;

                    float normalMapStrength;

                    vec3 hueShift( vec3 color, float hueAdjust ){

                        const vec3  kRGBToYPrime = vec3 (0.299, 0.587, 0.114);
                        const vec3  kRGBToI      = vec3 (0.596, -0.275, -0.321);
                        const vec3  kRGBToQ      = vec3 (0.212, -0.523, 0.311);
                    
                        const vec3  kYIQToR     = vec3 (1.0, 0.956, 0.621);
                        const vec3  kYIQToG     = vec3 (1.0, -0.272, -0.647);
                        const vec3  kYIQToB     = vec3 (1.0, -1.107, 1.704);
                    
                        float   YPrime  = dot (color, kRGBToYPrime);
                        float   I       = dot (color, kRGBToI);
                        float   Q       = dot (color, kRGBToQ);
                        float   hue     = atan (Q, I);
                        float   chroma  = sqrt (I * I + Q * Q);
                    
                        hue += hueAdjust;
                    
                        Q = chroma * sin (hue);
                        I = chroma * cos (hue);
                    
                        vec3    yIQ   = vec3 (YPrime, I, Q);
                    
                        return vec3( dot (yIQ, kYIQToR), dot (yIQ, kYIQToG), dot (yIQ, kYIQToB) );
                    }               

                    vec3 czm_saturation(vec3 rgb, float adjustment)
                    {
                        // Algorithm from Chapter 16 of OpenGL Shading Language
                        const vec3 W = vec3(0.2125, 0.7154, 0.0721);
                        vec3 intensity = vec3(dot(rgb, W));
                        return mix(intensity, rgb, adjustment);
                    }

                    void main() {
                        vec2 vp = vec2(plasmaTiling, plasmaTiling);
                        float t = uTime * plasmaSpeed;
                        vec2 p0 = (myUV - 0.5) * vp;
                        vec2 hvp = vp * 0.5;
                        vec2 p1d = vec2(cos( t / 98.0),  sin( t / 178.0)) * hvp - p0;
                        vec2 p2d = vec2(sin(-t / 124.0), cos(-t / 104.0)) * hvp - p0;
                        vec2 p3d = vec2(cos(-t / 165.0), cos( t / 45.0))  * hvp - p0;
                        float plasma = 0.5 + 0.5 * (
                            cos(length(p1d) / 30.0) +
                            cos(length(p2d) / 20.0) +
                            sin(length(p3d) / 25.0) * sin(p3d.x / 20.0) * sin(p3d.y / 15.0));

                        float OGPlasma = plasma;

                        plasma = pow(plasma, plasmaPow);

                        vec2 plasmaUV = vec2((1.0-plasma) * plasmaMax, 0.5);

                        vec3 plasmaCol = texture2D(plasmaMap, plasmaUV).rgb;     
                        
                        //darken plasma 
                        plasmaCol *= 0.25;
                            
                        //hue shift plasma
                        plasmaCol = hueShift(plasmaCol, plasmaHueShift);

                        plasmaCol = czm_saturation(plasmaCol, plasmaSat);

                        //flip y of myUV
                        vec2 myUVFlipped = vec2(myUV.x, 1.0 - myUV.y);

                        float maskAmt = 1.0 - texture2D(capeMask, myUVFlipped).r;

                        csm_Metalness = mix(maskAmt, baseMetalness, 1.0);

                        float plasmaColAmt = clamp(plasma * plasmaOpacity * plasmaBoost, 0.0, 1.0);

                        vec3 plasmaTextCol = mix(textCol, plasmaCol, plasmaColAmt); 

                        vec4 outCol = vec4(mix(csm_DiffuseColor.rgb, plasmaTextCol, maskAmt), 1.0);

                        // plasmaCol = mix(outCol.rgb, plasmaCol, plasmaOpacity);
                        // float finalPlasmaAmt = plasmaBoost * plasma * maskAmt; //clamp(plasmaBoost * plasma * maskAmt, 0.0, 1.0);
                        // outCol = vec4(mix(outCol.rgb, plasmaCol, finalPlasmaAmt), 1.0);

                        csm_DiffuseColor = outCol;

                        vec2 myUVTiled = myUVFlipped * 2.0;
                        float bronzeRoughness = texture2D(capeRoughness, myUVTiled).r;
                        bronzeRoughness = pow(bronzeRoughness, 1.5);

                        float plasmaRoughness = (1.0 - OGPlasma) * 0.5 + 0.5;

                        csm_Roughness = mix(baseRoughness, 0.0 + bronzeRoughness, maskAmt * plasmaRoughness);

                        vec2 normalUV = myUV * normalTiling;

                        csm_NormalMap = texture2D( normalMap, normalUV).xyz * 2.0;
                        normalMapStrength = (1.0 - maskAmt) * baseNormalStrength;
                    }
                    `
                    }
                    patchMap={{
                        csm_NormalMap: {
                          "#include <normal_fragment_maps>": glsl`
                            vec3 mapN = csm_NormalMap;
                            mapN.xy *= normalMapStrength;
                            normal = perturbNormal2Arb( - vViewPosition, normal, mapN, faceDirection );
                          `,
                        },
                    }}
                    uniforms={{
                        uTime: {
                            value: 0,
                        },
                        capeFlapAmt: {
                            value: capeFlapAmt,
                        },
                        capeMask: {
                            value: capeMask,
                        },
                        capeRoughness: {
                            value: capeRoughness,
                        },
                        plasmaPow: {
                            value: capePlasmaPow,
                        },
                        plasmaMap: {
                            value: heartPlasma,
                        },
                        plasmaTiling: {
                            value: capePlasmaTiling,
                        },
                        plasmaSpeed: {
                            value: capePlasmaSpeed, 
                        },
                        plasmaMax: {
                            value: capePlasmaMax,
                        },
                        plasmaHueShift: {
                            value: capePlasmaHueShift,
                        },
                        plasmaBoost: {
                            value: capePlasmaBoost,
                        },
                        plasmaSat: {
                            value: capePlasmaSat,
                        },
                        plasmaOpacity: {
                            value: capePlasmaOpacity,
                        },
                        textCol: {
                            value: new THREE.Color(capeTextCol),
                        },
                        baseRoughness: {
                            value: capeBaseRoughness,
                        },
                        baseMetalness: {
                            value: capeBaseMetalness,
                        },
                        baseNormalStrength: {
                            value: capeNormalStrength,
                        },
                        normalTiling: {
                            value: capeNormalTiling,
                        }
                    }}
                    color={0xffffff}
                    transparent={true}
                    normalMap={capeNormal}
                    normalMap-encoding={THREE.LinearEncoding}
                    envMapIntensity={capeEnvMapIntensity}
                    roughness={0.0}
                    map={capeTex}
                    map-flipY={false}
                    side={THREE.DoubleSide}
                    // ...
                />

            </mesh>
        </group>
        { sunRef && (
                <EffectComposer>

                    <HueSaturation
                        hue={hue}
                        saturation={saturation}
                    />

                    <BrightnessContrast
                        brightness={brightness}
                        contrast={contrast}
                    />

                    {bloomActive && <Bloom
                    luminanceThreshold={BloomThreshold}
                    luminanceSmoothing={BloomSmoothing}
                    height={BloomHeight}
                    intensity={BloomIntensity}
                    />}

                    {ssaoActive && (
                    <SSAO  
                    samples={30} 
                    bias={AOBias}
                    radius={AOradius} 
                    intensity={AOintensity} 
                    luminanceInfluence={0.9} 
                    color="black" 
                    worldDistanceThreshold={AODistThresh} 
                    worldDistanceFalloff={AODistFallOff}
                    />)}
                                        
                    {godRaysActive && (
                        <GodRays 
                            sun={sunRef}
                            blendFunction={BlendFunction.Screen} // The blend function of this effect.
                            samples={samples} // The number of samples per pixel.
                            density={density} // The density of the light rays.
                            decay={decay} // An illumination decay factor.
                            weight={weight} // A light ray weight factor.
                            exposure={exposure} // A constant attenuation coefficient.
                            clampMax={1} // An upper bound for the saturation of the overall effect.
                            width={Resizer.AUTO_SIZE} // Render width.
                            height={Resizer.AUTO_SIZE} // Render height.
                            kernelSize={KernelSize.SMALL} // The blur kernel size. Has no effect if blur is disabled.
                            blur={true} // Whether the god rays should be blurred to reduce artifacts.
                        />
                    )}
                </EffectComposer>                   
        )}
        </>
    )
}    
  
export default function OneOfOneOneOne(props) {

    const { godRaysActive, heartParticlesActive, ssaoActive, bloomActive, skysphereActive } = useControls('performance',
    {
        godRaysActive: true,
        heartParticlesActive: true,
        ssaoActive: true,
        bloomActive: true,
        skysphereActive: true,
    });

    const { camDistMin, camDistMax, bgCol, fogCol, fogNear, fogFar, lightCol, shadowCameraFar, shadowCameraNear, lightIntensity, useLinearColor } = useControls('scene', {
        camDistMin: { value: 1.0, min: 0.0, max: 100.0 },
        camDistMax: { value: 9.0, min: 0.0, max: 100.0 },
        fogNear: {
            value: 5,
            min: 0.0,
            max: 1000,
            step: 0.1,
        },
        fogFar: {
            value: 30,
            min: 0.0, 
            max: 5000,
            step: 0.1,
        },
        shadowCameraFar: {
            value: 100,
            min: 0.0,
            max: 1000,
            step: 0.1,
        },
        shadowCameraNear: {
            value: 0.1,
            min: 0.0,
            max: 1000,
            step: 0.1,
        },
        lightIntensity: {
            value: 3.0,
            min: 0.0,
            max: 10.0,
            step: 0.1,
        },
        useLinearColor: false,
        fogCol: '#9c9c9c',
        lightCol: '#ffdfb0',
        bgCol: '#313131'
    });

    const desktopFocalOffsetFactor = 7.0;
    const mobileFocalOffsetFactor = 2.5;

    const [mintedWorks, setMintedWorks] = useState([]);
    const numMintedWorksRef = useRef(0);
    
    //todo make this dynamic
    const mintedWorkIDRef = useRef(0);

    const recordingRef = useRef(false);

    const recordButtonRef = useRef();

    const playheadRef = useRef();

    const separatorRef = useRef();
    const infoPaneRef = useRef();
    const mintPanelRef = useRef();
    const timelineContainerRef = useRef();

    const camControlsRef = useRef();

    const panUpRef = useRef(false);
    const panDownRef = useRef(false);
    const panLeftRef = useRef(false);
    const panRightRef = useRef(false);

    const draggingSeparator = useRef(false);

    const popupRef = useRef(null);

    const hidArrows = useRef(false);

    const splitContainerRef = useRef(false);

    const [canRecord, setCanRecord] = useState(false); 

    const [contract, setContract] = useState(null);

    const songRef = useRef(null);
    const songIconRef = useRef(null);
    const windRef = useRef(null);
    const windIconRef = useRef(null);

    //WEB3
    const [infuraProvider, setInfuraProvider] = useState(null);

    const [whitelistTree, setWhitelistTree] = useState(null);
    const [web3Modal, setWeb3Modal] = useState(null);
    
    const [address, setAddress] = useState("");
    const [humanAddress, setHumanAddress] = useState("");
    const [connected, setConnected] = useState(false);
    const [connectText, setConnectText] = useState("wallet connect");

    const [ethersProvider, setEthersProvider] = useState(null);
    const [web3ModalProvider, setWeb3ModalProvider] = useState(null);  

    const [eligibleForDiscount, setEligibleForDiscount] = useState(false);

    const [mintText, setMintText] = useState({
        1: "MINT 1",
        5: "MINT 5",
        10: "MINT 10",
        15: "MINT 15"
    });    

    const sizes = 
        {
            rootWidth: "500px",
            controlWidth: "160px",
            scrubberWidth: "8px",
            scrubberHeight: "16px",
            rowHeight: "24px",
            folderHeight: "20px",
            checkboxSize: "16px",
            joystickWidth: "100px",
            joystickHeight: "100px",
            colorPickerWidth: "160px",
            colorPickerHeight: "100px",
            monitorHeight: "60px"
        };

    const levaTheme = {
        sizes
    }

    // const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, slidesToScroll: "auto" }, [Autoplay({ delay: 2000, stopOnInteraction: false })]);

    const carouselSettings = {
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const previewHoverRef = useRef(null);
    const previewPaintingRef = useRef(null);
      
    function getRandomDarkHexColor() {
        let hexColor;
        let luminance;
        
        do {
          hexColor = '#' + Math.random().toString(16).substring(2, 8);
          luminance = calculateLuminance(hexColor);
        } while (luminance >= 0.5);
        
        return hexColor;
    }
      
    function calculateLuminance(hexColor) {
        //extract r,g,b from the hex color, in the range 0-255
        let r = parseInt(hexColor.substr(1, 2), 16);
        let g = parseInt(hexColor.substr(3, 2), 16);
        let b = parseInt(hexColor.substr(5, 2), 16);
    
        console.log('r ' + r + ' g ' + g + ' b ' + b);
    
        //calculate the luminance of the color
        let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        //normalize the luminance to the range 0-1
        luminance = luminance / 255;

        return luminance;
    }

    async function initMerkleTree() {
        const leafNodes = CREATION_WHITELIST.map(address => keccak256(address));
    
        const tree = new MerkleTree(leafNodes, keccak256, {sortPairs: true});
    
        const rootHash = tree.getRoot();
        const rootHashHex = MerkleTree.bufferToHex(rootHash);

        console.log("root hash is " + rootHashHex);
    
        setWhitelistTree(tree);
    }    

    async function connectWallet() {
        console.log('connecting web3Modal..');
        const provider = await web3Modal.connect();
    
    
        const ethersProvider = new providers.Web3Provider(provider);
    
        const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
        console.log("chain id is " + chainId);
    
        if(chainId !== targetChainID) {
          setConnected(false);
          setConnectText(wrongNetworkMessage);
          return;
        }
            
        const userAddress = await ethersProvider.getSigner().getAddress();
    
        const name = await ethersProvider.lookupAddress(userAddress);
    
        const eligible = CREATION_WHITELIST.some(addr => {
          return addr.toLowerCase() === userAddress.toLowerCase();
        });
        
        //UPDATE STATE VARIABLES 
        setWeb3ModalProvider(provider);
        setEthersProvider(ethersProvider);
        setEligibleForDiscount(eligible);    

        console.log('user on whitelist: ' + eligible);
    
        setAddress(userAddress);
    
        if(name) {
          setHumanAddress(name);
        }
    
        else {
          let truncatedAddress = truncateEthAddress(userAddress);
          setHumanAddress(truncatedAddress);
        }
        
        setConnected(true);
    }

    async function mintCreationBaby(quantity) {
        try {
            console.log('minting ' + quantity + ' cakes');
      
            let signer = ethersProvider.getSigner();
      
            const contract = new Contract(CREATION_BABIES_ADDRESS, CREATION_BABIES_ABI, signer);

            let tx;

            if(eligibleForDiscount) {
                const price = await contract.checkPrice(quantity, address, true);

                console.log('price for ' + quantity + ' babies is ' + price + ' (discounted)');

                const hexProof = whitelistTree.getHexProof(keccak256(address));
                tx = await contract.mintWhitelisted(quantity, hexProof, {
                    value: price
                });
            }

            else {
                const price = await contract.checkPrice(quantity, address, false);

                console.log('price for ' + quantity + ' babies is ' + price);

                tx = await contract.mint(quantity, {
                    value: price
                });
            }
      
            //make a copy of mintLoading
            let newMintText = {...mintText};
            newMintText[quantity] = "MINTING...";    
            setMintText(newMintText);
      
            await tx.wait();
      
            let newMintText2 = {...mintText};
            newMintText2[quantity] = "MINTED!";
            setMintText(newMintText2);
      
            console.log('minting complete');
          }
          
          catch(err) {
            console.log(err.message);
      
            if(err.message.includes("insufficient funds")) {
              alert("Insufficient funds! Make sure you have enough ETH in your wallet.");
            }
          }      
    }
    

    //TODO refresh timeline data every so often
    useEffect(() => {
        async function loadContractData() {
            // const numMinted = await contract.totalSupply();
            // console.log(numMinted); 

            const now = Math.round(Date.now() / 1000);

            const mintStart = await contract.mintStartTime();

            //set the text of div ID mintStartTime to the mint start time in the form mm/dd/yyyy
            document.getElementById('Mint-start-time').innerHTML = new Date(mintStart * 1000).toLocaleDateString();

            // add '/n mint start' to the text
            document.getElementById('Mint-start-time').innerHTML += ' <br/> mint start';

            let mintedWorksTemp = [];

            //call contract method 'getTokenInfo' which returns an array of addresses and an array of uint256 timestamps
            const tokenInfo = await contract.getTokenInfo();

            //get array of addresses
            const tokenOwners = tokenInfo[0];
            const tokenTimestamps = tokenInfo[1];

            //if the number of minted tokens is the same as the length of mintedWorks, then we don't need to update the timeline
            //in that case, return
            if(numMintedWorksRef.current == tokenOwners.length) {
                // console.log('no new tokens minted, returning')
                return;
            }

            const numMinted = tokenOwners.length;

            for(let i = 0; i < numMinted; i++) {
                // var ownerOf = await contract.ownerOf(i);
                var ownerOf = tokenOwners[i];

                // console.log('owner of token ' + i + ' is ' + ownerOf);

                // const timeMinted = await contract.mintedAt(i);
                const timeMinted = tokenTimestamps[i];
                const timeMintedPerc = (timeMinted - mintStart) / (now - mintStart);

                // console.log('token ' + i + ' minted at ' + timeMintedPerc + '% of the way through the minting period');

                //let color = '#' + Math.random().toString(16).substring(2, 8);
                let color = getRandomDarkHexColor();
                // console.log('color is ' + color);

                let mintedWork = {
                    owner: ownerOf,
                    timeMinted: timeMinted,
                    timeMintedPerc: timeMintedPerc,
                    color: color
                }

                mintedWorksTemp.push(mintedWork);
            }

            mintedWorksTemp.sort((a, b) => (a.timeMintedPerc > b.timeMintedPerc) ? 1 : -1);
            console.log('setting minted works..');
            numMintedWorksRef.current = mintedWorksTemp.length;
            setMintedWorks(mintedWorksTemp);
        }

        if(contract != null && infuraProvider != null) {
            loadContractData();

            //call this function every 20 seconds
            const interval = setInterval(() => {
                // console.log('refreshing contract data...')
                loadContractData();
            }
            , 20000);
        }

    }, [contract, infuraProvider]);

    useEffect(() => {
        document.title = props.title;

        initMerkleTree();

        OverrideMaterialManager.workaroundEnabled = true;

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);    

        document.addEventListener("touchmove", onTouchMove);
        document.addEventListener("touchend", onTouchEnd);

        //add an event listener to splitContainerRef the first time it is clicked
        splitContainerRef.current.addEventListener("click", function() {
            //play audio of windRef
            windRef.current.play();
            windIconRef.current.src = require('./images/nowind.png');
        }, { once: true });

        let infuraProviderTemp = new providers.InfuraProvider(targetChainName, INFURA_KEY);

        //connect to contract
        const contractTemp = new Contract(CREATION_BABIES_ADDRESS, CREATION_BABIES_ABI, infuraProviderTemp);
        setContract(contractTemp);

        setInfuraProvider(infuraProviderTemp);

        //todo infura id
        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: INFURA_KEY
                }
            }  
        };
  
        const web3Modal = new Web3Modal({
            network: targetChainName, // optional
            cacheProvider: true, // optional
            providerOptions // required
        });
          
        setWeb3Modal(web3Modal);
        console.log(web3Modal);

    }, []);

    //connect if cached provider
    // useEffect(() => {
    //     if(web3Modal && web3Modal.cachedProvider) {
    //       connectWallet();
    //     }
    // }, [web3Modal]);
    
    useEffect(() => {
        if(web3ModalProvider) {
            web3ModalProvider.on("accountsChanged", (accounts) => {
            console.log(accounts);

            if(accounts.length > 0) {
                connectWallet();
            }

            else {
                setConnected(false);
                setConnectText("wallet connect");
            }
            });

            web3ModalProvider.on("chainChanged", (chainId) => {
                console.log("chain changed, id is " + chainId);
                setConnected(false);

                if(connected) {
                    setConnectText(wrongNetworkMessage);
                }
                
                connectWallet();
            });    
        }
    }, [web3ModalProvider, connected]);

    const onMouseMove = (e) => {
        if(!draggingSeparator.current) {
            return;
        }

        else if(draggingSeparator.current == true) {


            let separatorWidth = separatorRef.current.clientWidth;

            let newRight = 1.0 - (e.clientX / window.innerWidth) - (separatorWidth / window.innerWidth) / 2.0;

            separatorRef.current.style.right = newRight * 100 + '%';
            infoPaneRef.current.style.width = newRight * 100 + '%';
            mintPanelRef.current.style.width = newRight * 100 + '%';

            timelineContainerRef.current.style.width = (Math.max(1.0 - newRight - .05, 0.0)) * 100 + '%';

            //don't hide arrows for now
            // if(hidArrows.current == false) {
            //     hideArrows();
            // }

            if(camControlsRef.current) {
                //calculate offset based on newRight
                let offset = newRight * desktopFocalOffsetFactor;
                camControlsRef.current.setFocalOffset(offset, 0.0, 0.0, true);
            }
        }

    };
    
    const onMouseUp = () => {
        draggingSeparator.current = false;
    };

    const onMouseDown = (e) => {
        console.log('dragging');
        draggingSeparator.current = true;
    };

    const onTouchDown = (e) => {
        console.log('touching');
        draggingSeparator.current = true;
    };

    function hideArrows() {
        let arrowL = document.getElementsByClassName('Drag-arrow-L')[0];
        let arrowR = document.getElementsByClassName('Drag-arrow-R')[0];

        //set animation of arrowL and arrowR to fadeOut
        arrowL.style.animation = 'hue 15s infinite, wiggleL 1s infinite, fadeOut 1s forwards';
        arrowR.style.animation = 'hue 15s infinite, wiggleR 1s infinite, fadeOut 1s forwards';

        hidArrows.current = true;
    }

    const onTouchMove = (e) => {
        if(!draggingSeparator.current) {
            return;
        }

        else if(draggingSeparator.current == true) {
            let separatorWidth = separatorRef.current.offsetWidth;

            let newRight = 1.0 - (e.touches[0].clientX / window.innerWidth) - (separatorWidth / window.innerWidth) / 2.0; 

            separatorRef.current.style.right = newRight * 100 + '%';
            infoPaneRef.current.style.width = newRight * 100 + '%';
            mintPanelRef.current.style.width = newRight * 100 + '%';

            timelineContainerRef.current.style.width = (Math.max(1.0 - newRight - .05, 0.0)) * 100 + '%';

            // if(hidArrows.current == false) {
            //     hideArrows();
            // }

            if(camControlsRef.current) {
                //calculate offset based on newRight
                let offset = newRight * mobileFocalOffsetFactor;
                camControlsRef.current.setFocalOffset(offset, 0.0, 0.0, true);
            }
        }
    };

    const onTouchEnd = () => {
        draggingSeparator.current = false;
    };

    function enablePanUp() {
        panUpRef.current = true;
    }

    function disablePanUp() {
        panUpRef.current = false;
    }

    function enablePanDown() {
        panDownRef.current = true;
    }

    function disablePanDown() {
        panDownRef.current = false;
    }

    function enablePanLeft() {
        panLeftRef.current = true;
    }

    function disablePanLeft() {
        panLeftRef.current = false;
    }

    function enablePanRight() {
        panRightRef.current = true;
    }

    function disablePanRight() {
        panRightRef.current = false;
    }

    return (
        <div className="Split-container" ref={splitContainerRef}>
            {/* <audio id="audio" src={process.env.PUBLIC_URL + '/audio/creationbabies.mp3'} type={"audio/mpeg"} autoPlay loop></audio> */}

            {/* <div className="Info-popup-container" ref={popupRef} onClick={() => {
                popupRef.current.classList.remove('visible');
                popupRef.current.style.pointerEvents = 'none';

                //in one second, remove popupRef from dom
                setTimeout(() => {
                    popupRef.current.remove();
                }, 2000);

                var audio = new Audio(process.env.PUBLIC_URL + '/audio/wind.mp3');
                //set audio to loop
                audio.loop = true;
                audio.play();
            }}>
                <div className="Info-popup">
                    <i>Welcome to Mt. "Get Back in the Game" 🎻</i><br /><br/>

                    <div className="Navigation-tips">
                        ♻️ {isMobile ? "tap and drag to orbit" : "click and drag to orbit"}<br/><br/>
                        👼 {isMobile ? "pinch to zoom" : "scroll to zoom"}<br/><br/>
                        🔧 {isMobile ? "use the mud arrows to pan" : "use the mud arrows to pan"}<br/><br/>
                    </div>

                    <div className="Click-to-start">
                    <i>{isMobile ? "tap anywhere to start" : "click anywhere to start"}</i>
                    </div>
                </div>
            </div> */}

            {connected && <div className="Address-pill">{humanAddress}</div>}
            {!connected && <button className="Connect-button" onClick={() => connectWallet()}>{connectText}</button>}

            <div className="Split-separator" 
                onMouseDown={onMouseDown} 
                onTouchStart={onTouchDown}
                ref={separatorRef}>

                <div className="Drag-arrow-L">←</div>
                <div className="Drag-arrow-R">→</div>
            </div>

            <div className="OneOfOneOneOne-container">
                <div className="Loading">
                    <img src={require('./images/creation_babies_logo.png')} className="Creation-logo-loading"/>
                    <img src={require('./images/by_jared.png')} className="Creation-logo-loading"/>
                </div>

                <div className='Timeline-container' ref={timelineContainerRef}>
                    <div id="Mint-start-time" className='Timeline-date'></div>
                    <div className='Timeline-line'> 
                        <div className='Timeline-line-thick'></div>

                        {playheadRef.current && mintedWorks.map((work, index) => {
                            return (
                                <div className='Timeline-point' 
                                    key={index} 
                                    style={{
                                        left: work.timeMintedPerc * 100 + '%', 
                                        background: work.color
                                    }}>
                                </div>
                            )
                        })
                        }
                        <div className='Timeline-playhead' ref={playheadRef}></div>
                    </div>
                    <div className='Timeline-date'>
                        {/* put current date here in the form mm/dd/yy */
                        new Date().toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: '2-digit'})
                        }<br/>
                        now
                    </div>
                </div>

                <Canvas 
                    gl={{ preserveDrawingBuffer: true }}
                    dpr={[0.5, 1.0]} 
                    linear={useLinearColor}
                    camera={{position:[-2,1.5,-5]}}
                    performance={{ min: 0.5 }}
                    shadows
                >
                    <AdaptiveDpr pixelated/>
                    <color attach="background" args={[bgCol]} />
                    <fog attach="fog" color={fogCol} near={fogNear} far={fogFar} />
                    {/* <ambientLight /> */}
                    <directionalLight 
                    position={[0, 10, 0]} 
                    color={lightCol} 
                    rotateX={[Math.PI]}
                    intensity={lightIntensity}
                    castShadow  
                    shadow-mapSize-width={2048}
                    shadow-mapSize-height={2048}
                    shadow-camera-far={shadowCameraFar}
                    shadow-camera-near={shadowCameraNear}
                    shadow-camera-left={-10}
                    shadow-camera-right={10}
                    shadow-camera-top={10}
                    shadow-camera-bottom={-10}
                    />

                    <SceneContext.Provider value={ 
                        {
                            controls: camControlsRef,
                            mintedWorkID: mintedWorkIDRef,
                            recording: recordingRef,
                            panUp: panUpRef,
                            panDown: panDownRef,
                            panLeft: panLeftRef,
                            panRight: panRightRef,
                            canRecord: canRecord,
                            provider: infuraProvider,
                            previewHover: previewHoverRef,
                            previewPainting: previewPaintingRef,
                            popup: popupRef,
                        } 
                    }>
                        <CamControls 
                            ref={camControlsRef}
                            minDistance={camDistMin} 
                            maxDistance={camDistMax}    
                            useFocalOffset={true}                    
                        />

                        <Scene 
                            mintedWorks={mintedWorks}
                            heartParticlesActive={heartParticlesActive} 
                            skysphereActive={skysphereActive}
                            godRaysActive={godRaysActive}
                            ssaoActive={ssaoActive}
                            bloomActive={bloomActive}
                            playTimeline={true}
                        />
                    </SceneContext.Provider>

                    <Suspense fallback={null}>
                    <Environment
                    files={['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']}
                    path={process.env.PUBLIC_URL + '/textures/env/'}
                    />
                    </Suspense>

                    {/* <Stats showPanel={0} className="stats" {...props} />        */}
                </Canvas>
                
                <Leva
                        collapsed// default = false, when true the GUI is collpased
                        theme={levaTheme}
                        hidden
                />
            </div>

            {/* {!isMobile && canRecord && <div className="RecordMintButton" ref={recordButtonRef} onClick={toggleRecord}>Record Mint Video 🔴</div>} */}

            <Draggable>
                <div className="Pan-arrows">
                    <div className="Pan-arrow-up" onPointerDown={enablePanUp} onPointerUp={disablePanUp}>
                        <img draggable={false} src={require('./images/mud_arrow.webp')}/>
                    </div>
                    <div className="Pan-arrow-down" onPointerDown={enablePanDown} onPointerUp={disablePanDown}>
                        <img draggable={false} src={require('./images/mud_arrow.webp')}/>
                    </div>
                    <div className="Pan-arrow-left" onPointerDown={enablePanLeft} onPointerUp={disablePanLeft}>
                        <img draggable={false} src={require('./images/mud_arrow.webp')}/>
                    </div>
                    <div className="Pan-arrow-right" onPointerDown={enablePanRight} onPointerUp={disablePanRight}>
                        <img draggable={false} src={require('./images/mud_arrow.webp')}/>
                    </div>

                    <div className="Pan-ball">
                        <img draggable={false} src={require('./images/mud_ball.webp')}/>
                    </div>
                </div>
            </Draggable>

            <div className="Audio-player">
                <button className="Audio-player-button" onClick={() => {
                    //toggle audio of windRef
                    if (windRef.current.paused) {
                        windIconRef.current.src = require('./images/nowind.png');
                        console.log('switching to nowind');
                        windRef.current.play();
                    }
                    else {
                        console.log('switching to wind');
                        windIconRef.current.src = require('./images/wind.png');
                        windRef.current.pause();
                    }
                }}> 
                    <img src={require('./images/wind.png')} ref={windIconRef}/>
                </button>

                <button className="Audio-player-button" onClick={() => {
                    //toggle audio of songRef
                    if (songRef.current.paused) {
                        songRef.current.play();
                        songIconRef.current.src = require('./images/pause.png');
                    }
                    else {
                        songRef.current.pause();
                        songIconRef.current.src = require('./images/play.png');
                    }
                }}>
                    <img src={require('./images/play.png')} ref={songIconRef}/>
                </button>

                <audio ref={songRef}>
                        <source src={process.env.PUBLIC_URL + "/audio/creationbabies.mp3"} type="audio/mpeg"/>
                </audio>
                <audio ref={windRef}>
                        <source src={process.env.PUBLIC_URL + "/audio/wind.mp3"} type="audio/mpeg"/>
                </audio>

                Creation Babies Mint Song
            </div>

            <div className="Mint-panel" ref={mintPanelRef}>
                <div className="Mint-button-grid">
                    <button className="Mint-button" onClick={() => {if(connected) { mintCreationBaby(1); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>
                    {mintText[1]}
                    </button>
                    <button className="Mint-button" onClick={() => {if(connected) { mintCreationBaby(5); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>
                    {mintText[5]}
                    </button>
                    <button className="Mint-button" onClick={() => {if(connected) { mintCreationBaby(10); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>
                    {mintText[10]}
                    </button>
                    <button className="Mint-button" onClick={() => {if(connected) { mintCreationBaby(15); } else { alert("Wallet not connected, connect your wallet to mint"); }}}>
                    {mintText[15]}
                    </button>
                </div>
                <div className="Mint-pricing">
                    <div className="Mint-price-key">
                        <div className="Mint-prices">
                            <div className="Mint-price">
                                0.088E
                            </div>
                            <div className="Mint-price">
                                0.055E
                            </div>
                            <div className="Mint-price">
                                0.066E
                            </div>
                            <div className="Mint-price">
                                0.044E
                            </div>
                        </div>

                        <div className="Mint-price-conditions">
                            <div className="Mint-price-condition">
                                standard
                            </div>
                            <div className="Mint-price-condition">
                                {isMobile ? "🥧🔑" : "Pie"} 
                            </div>
                            <div className="Mint-price-condition">
                                {isMobile ? "🍰/WL" : "Cake/WL"} 
                            </div>
                            <div className="Mint-price-condition">
                                {isMobile ? "🍰/WL + 🥧🔑" : "Cake/WL and Pie"} 
                            </div>
                        </div>
                    </div>

                    <div className="Mint-quantity-discount">
                    Mint 5 = 5% off<br/>
                    Mint 10 = 10% off<br/>
                    Mint 15 = 15% off<br/>
                    </div>
                </div>

            </div>

            <div className="OneOfOneOneOne-info" ref={infoPaneRef}>
                {/* <img src={require('./images/creation_babies_logo.png')} className="Creation-logo"/>
                <img src={require('./images/by_jared.png')} className="Creation-logo"/> */}

                <div className="Creation-header">
                Creation Babies<br/> by Jared Madere 
                </div>

                <div className="Preview-container">
                    <div className="Preview-hover" ref={previewHoverRef}>
                        <img src={process.env.PUBLIC_URL + "/textures/paintings/1.jpg"} className="Carousel-img"/>
                        <div className='Preview-minted-by'>
                            minted by 
                        </div>
                    </div>
                    <div className="Preview-carousel">
                        <Slider {...carouselSettings}>
                            <img src={process.env.PUBLIC_URL + "/textures/paintings_med/20.jpg"} className="Carousel-img"/>
                            <img src={process.env.PUBLIC_URL + "/textures/paintings_med/21.jpg"} className="Carousel-img"/>
                            <img src={process.env.PUBLIC_URL + "/textures/paintings_med/22.jpg"} className="Carousel-img"/>
                            <img src={process.env.PUBLIC_URL + "/textures/paintings_med/23.jpg"} className="Carousel-img"/>
                            <img src={process.env.PUBLIC_URL + "/textures/paintings_med/24.jpg"} className="Carousel-img"/>
                        </Slider>
                    </div>
                </div> 

                {/* <div className="Mint-button-grid">
                    <div className="Mint-button" onClick={() => {console.log('mint')}}>
                    MINT 1
                    </div>
                    <div className="Mint-button" onClick={() => {console.log('mint')}}>
                    MINT 5
                    </div>
                    <div className="Mint-button" onClick={() => {console.log('mint')}}>
                    MINT 10
                    </div>
                    <div className="Mint-button" onClick={() => {console.log('mint')}}>
                    MINT 15
                    </div>
                </div> */}

                {/* <ReactAudioPlayer
                    src={require('./audio/creationbabies.mp3')}
                    controls
                />*/}

                {/* <img src={require('./images/jared_111.jpg')} alt="1/111" className="Info-img"/> */}
                <div className="Info-text">
                Creation Babies is a Unique Edition NFT by Jared Madere consisting of 111 unique digital paintings. Creation itself becomes the subject of these works as we see a mother figure caught in a frozen moment where she appears to be simultaneously ripping apart musical instruments—energetically transmuting their physical matter into the bodies of newborn babies, AND disassembling the bodies of children to use their material components to construct musical instruments.<br /><br />

                These paintings are created with the assistance of AI image synthesis tools which structurally mirror the exact ambiguity present in the choose-your-own-adventure narrative of the tender or daemonic mother described above. Much like the human imagination, AI tools behave like wildly influenceable assumption engines that witness the edge of a shadow and jump to hyperbolic conclusions that there is a ghost in the room.<br /><br />

                What is a Unique Edition?<br /><br />

                Yeche Lange has created a custom AI pipeline for augmenting works as well as a new distribution lane specifically geared at releasing Unique Editions—to give artists who are unaccustomed to working generatively, tools that allow them to produce works that play with the possibilities of scale that exist in Web3. The concept here being that any kind of visual artist can take time to create an intricate and thoughtful initial work that can then be selectively interpolated on to create unique variation within the unified whole of a Unique Edition collection.<br /><br />

                Yeche Lange has forever been dedicated to working with artists from a wide array of technical disciplines and relationships to technology. We believe that the future of art lies on chain, and that all artists have a place for their work here. The relatively new NFT market ushers in possibilities of scale never imagined in the traditional art world and we want to facilitate artists and their work expanding to fill the vistas afforded by Web3.<br /><br />

                In addition to a reimagining of the mechanics of the collection itself, we have also developed a new minting experience based on an immersive virtual architecture. The debut of Creation Babies occurs on the peak of Mt. Get Back In The Game ~ a monumental sculptural mountain made of mud that depicts a scene of a father patting his discouraged son on the shoulder at a baseball game encouraging him to not give up and continue giving the game his best shot. The boy sheds an infinitely looping tear from the fountain of his lacrimal, the father beams unconditional love from an undulating velvet mandolin shaped like a heart. As minters sign their contracts their freshly minted artworks rain from the sky splashing into the mud of the totemic mountain. Each artwork is tagged with the wallet address of the minter which allows each holder to access a custom portal where they can download a video of their artwork plummeting from the heavens as if a babe from a stork.<br /><br />

                <div className="Credit-line">
                    WebGL scene, website development and smart contract integration by Miles Peyton
                </div>

                </div>

                {/* <div className="Mint-button-grid">
                    <div className="Mint-button" onClick={() => {console.log('mint')}}>
                    MINT 1
                    </div>
                    <div className="Mint-button" onClick={() => {console.log('mint')}}>
                    MINT 5
                    </div>
                    <div className="Mint-button" onClick={() => {console.log('mint')}}>
                    MINT 10
                    </div>
                    <div className="Mint-button" onClick={() => {console.log('mint')}}>
                    MINT 15
                    </div>
                </div> */}

                <img src={require('./images/creation_babies_logo.png')} className="Creation-babies-pane"/>
                <img src={require('./images/by_jared.png')} className="Creation-babies-pane"/>

                <div className='Creation-footer'></div>
            </div>
        </div>
    );

}


export { Scene, SceneContext, CamControls };