import React from 'react';
import { useEffect } from 'react';

function CocoaStrawberry(props) {

  useEffect(() => {
    document.title = props.title;
  }, []);
    
  return (
    <iframe src="https://mileshiroo.github.io/cocoastrawberry-freemint/cocoa/" title="Cocoa Strawberry Club Free Mint" style={{position: "absolute", top: "15%"}} onLoad={window.scroll(0,0)} width="100%" height="100%" frameBorder="0" scrolling="yes" allowFullScreen></iframe>
  );
}

export default CocoaStrawberry;
