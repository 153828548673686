import { Scene, SceneContext, CamControls } from './1-111.js';
import React from 'react';
import * as THREE from 'three'
import { useEffect, useRef, useMemo, useCallback, useContext, useState, useImperativeHandle, forwardRef, Suspense, createContext } from 'react';
import { Canvas, useFrame, useThree, useLoader, extend } from '@react-three/fiber';
import { useGLTF, Stats, shaderMaterial, Environment, Text, Html, Billboard, AdaptiveDpr } from "@react-three/drei"
import { EffectComposer, DepthOfField, SSAO, GodRays, Bloom, SMAA } from '@react-three/postprocessing';
import { BlendFunction, Resizer, KernelSize, OverrideMaterialManager } from 'postprocessing';
import CustomShaderMaterial from 'three-custom-shader-material'
import glsl from 'babel-plugin-glsl/macro'
import { useControls, Leva, folder } from 'leva';
import { ImprovedNoise } from 'three/examples/jsm/math/ImprovedNoise.js';
import './1-111.css';
import { BackSide, BoxGeometry, Clock, GLSLVersion, Group, MeshBasicMaterial, PlaneGeometry } from 'three';
import { Camera } from 'three';
import { PerspectiveCamera } from 'three';
import { MeshStandardMaterial } from 'three';
import { lerp, clamp, smoothstep, mapLinear } from 'three/src/math/MathUtils';
import { matchRoutes } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import Draggable from 'react-draggable';

import { Contract, providers } from 'ethers';
import { isAddress } from 'ethers/lib/utils.js';
import CREATION_BABIES_ABI from './creation-babies-abi.json';

import {
    Routes,
    Route,
    useLocation
} from "react-router-dom";

const INFURA_KEY = '4ebebc5a34f44308ab4df40a00f21d34';
const CREATION_BABIES_ADDRESS = '0x96C161A071faA7a6cE24C591Cc697A9BC15dF72d';
const targetChainName = 'mainnet';

//dirty trick to hide warnings
window.console.warn = () => {};

export default function OneOfOneOneOneRecord(props) {

    const { godRaysActive, heartParticlesActive, ssaoActive, bloomActive, skysphereActive } = useControls('performance',
    {
        godRaysActive: true,
        heartParticlesActive: true,
        ssaoActive: true,
        bloomActive: true,
        skysphereActive: true,
    });

    const { bgCol, fogCol, fogNear, fogFar, lightCol, shadowCameraFar, shadowCameraNear, lightIntensity } = useControls('scene', {
        fogNear: {
            value: 5,
            min: 0.0,
            max: 1000,
            step: 0.1,
        },
        fogFar: {
            value: 30,
            min: 0.0, 
            max: 5000,
            step: 0.1,
        },
        shadowCameraFar: {
            value: 100,
            min: 0.0,
            max: 1000,
            step: 0.1,
        },
        shadowCameraNear: {
            value: 0.1,
            min: 0.0,
            max: 1000,
            step: 0.1,
        },
        lightIntensity: {
            value: 3.0,
            min: 0.0,
            max: 10.0,
            step: 0.1,
        },
        fogCol: '#9c9c9c',
        lightCol: '#ffdfb0',
        bgCol: '#313131'
    });

    const desktopFocalOffsetFactor = 7.0;
    const mobileFocalOffsetFactor = 2.5;

    const numMintedWorksRef = useRef(0);

    const [mintedWorks, setMintedWorks] = useState([]);
    
    //todo pull this from web3
    const mintedWorkIDRef = useRef(5);

    const recordingRef = useRef(false);

    const recordButtonRef = useRef();

    const playheadRef = useRef();

    const separatorRef = useRef();
    const infoPaneRef = useRef();

    const camControlsRef = useRef();

    const panUpRef = useRef(false);
    const panDownRef = useRef(false);
    const panLeftRef = useRef(false);
    const panRightRef = useRef(false);

    const draggingSeparator = useRef(false);

    const hidArrows = useRef(false);

    const { CanvasCapture } = require('canvas-capture');

    const [canRecord, setCanRecord] = useState(!isMobile); 

    //WEB3
    const [contract, setContract] = useState(null);
    const [infuraProvider, setInfuraProvider] = useState(null);

    const walletContainerRef = useRef(null);
    const walletInputRef = useRef(null);
    const recordInstructions = useRef(null);

    const priorButtonText = useRef(null);

    const tokenOwnersRef = useRef([]);

    const location = useLocation();

    const sizes = 
        {
            rootWidth: "500px",
            controlWidth: "160px",
            scrubberWidth: "8px",
            scrubberHeight: "16px",
            rowHeight: "24px",
            folderHeight: "20px",
            checkboxSize: "16px",
            joystickWidth: "100px",
            joystickHeight: "100px",
            colorPickerWidth: "160px",
            colorPickerHeight: "100px",
            monitorHeight: "60px"
        };

    const levaTheme = {
        sizes
    };

    function getRandomDarkHexColor() {
        let hexColor;
        let luminance;
        
        do {
          hexColor = '#' + Math.random().toString(16).substring(2, 8);
          luminance = calculateLuminance(hexColor);
        } while (luminance >= 0.5);
        
        return hexColor;
    }
      
    function calculateLuminance(hexColor) {
        //extract r,g,b from the hex color, in the range 0-255
        let r = parseInt(hexColor.substr(1, 2), 16);
        let g = parseInt(hexColor.substr(3, 2), 16);
        let b = parseInt(hexColor.substr(5, 2), 16);
    
        console.log('r ' + r + ' g ' + g + ' b ' + b);
    
        //calculate the luminance of the color
        let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        //normalize the luminance to the range 0-1
        luminance = luminance / 255;

        return luminance;
    }

    useEffect(() => {
        async function loadContractData() {
            // const numMinted = await contract.totalSupply();
            // console.log(numMinted); 

            const now = Math.round(Date.now() / 1000);

            const mintStart = await contract.mintStartTime();

            let mintedWorksTemp = [];

            //call contract method 'getTokenInfo' which returns an array of addresses and an array of uint256 timestamps
            const tokenInfo = await contract.getTokenInfo();

            //get array of addresses
            const tokenOwners = tokenInfo[0];

            const tokenOwnersLower = tokenOwners.map(owner => owner.toLowerCase());

            tokenOwnersRef.current = tokenOwnersLower;

            const tokenTimestamps = tokenInfo[1];

            //if the number of minted tokens is the same as the length of mintedWorks, then we don't need to update the timeline
            //in that case, return
            if(numMintedWorksRef.current == tokenOwners.length) {
                // console.log('no new tokens minted, returning')
                return;
            }

            const numMinted = tokenOwners.length;
            console.log('numMinted is ' + numMinted);

            for(let i = 0; i < numMinted; i++) {
                // var ownerOf = await contract.ownerOf(i);
                var ownerOf = tokenOwners[i];

                // console.log('owner of token ' + i + ' is ' + ownerOf);

                // const timeMinted = await contract.mintedAt(i);
                const timeMinted = tokenTimestamps[i];
                const timeMintedPerc = (timeMinted - mintStart) / (now - mintStart);

                // console.log('token ' + i + ' minted at ' + timeMintedPerc + '% of the way through the minting period');

                //let color = '#' + Math.random().toString(16).substring(2, 8);
                let color = getRandomDarkHexColor();
                // console.log('color is ' + color);

                let mintedWork = {
                    owner: ownerOf,
                    timeMinted: timeMinted,
                    timeMintedPerc: timeMintedPerc,
                    color: color
                }

                mintedWorksTemp.push(mintedWork);
            }

            mintedWorksTemp.sort((a, b) => (a.timeMintedPerc > b.timeMintedPerc) ? 1 : -1);
            console.log('setting minted works..');
            numMintedWorksRef.current = mintedWorksTemp.length;
            setMintedWorks(mintedWorksTemp);
        }

        if(contract != null && infuraProvider != null) {
            loadContractData();
        }

    }, [contract, infuraProvider]);


    useEffect(() => {
        document.title = props.title;

        if(!isMobile) {
            OverrideMaterialManager.workaroundEnabled = true;

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);    

            document.addEventListener("touchmove", onTouchMove);
            document.addEventListener("touchend", onTouchEnd);

            //add event listener to walletInputRef to get wallet address
            walletInputRef.current.addEventListener('keydown', (e) => {
                if(e.key !== 'Enter') {
                    return;
                }

                console.log('wallet address is ' + e.target.value);
                let address = e.target.value;
                let addressLower = address.toLowerCase();
                console.log('is address: ' + isAddress(address));

                if(!isAddress(address)) {
                    alert('Please enter a valid Ethereum address');
                }

                else {
                    //check if address is contained in tokenOwnersRef.current
                    console.log('tokenOwnersRef.current is ' + tokenOwnersRef.current)
                    if(tokenOwnersRef.current.includes(addressLower)) {
                        console.log('address is in tokenOwnersRef.current');
                        //hide input 
                        walletInputRef.current.style.display = 'none';
                        //hide record instruction text
                        recordInstructions.current.style.display = 'none';

                        //for every token owned by this address, add a button to the page of class RecordMintButton
                        let tokensIDs = [];
                        for(let i = 0; i < tokenOwnersRef.current.length; i++) {
                            if(tokenOwnersRef.current[i] == addressLower) {
                                tokensIDs.push(i);
                                // console.log('found a match, adding a button');
                                //add a button as a child of walletContainerRef.current
                                let button = walletContainerRef.current.appendChild(document.createElement('button'));

                                button.classList.add('RecordMintButton');

                                let numString = (i+1).toString();
                                while(numString.length < 3) {
                                    numString = '0' + numString;
                                }

                                //set the text to Record Mint Video for Creation Baby numString
                                button.innerText = '🔴 Record Mint: Creation Baby ' + numString;

                                //add an event listener to button when it's clicked
                                button.addEventListener('click', async () => {
                                    priorButtonText.current = '🔴 Record Mint: Creation Baby ' + numString;
                                    mintedWorkIDRef.current = i;
                                    recordButtonRef.current = button;
                                    toggleRecord();
                                });

                            }
                        }

                    }
                    else {
                        alert("hm, looks like you haven't minted a Creation Baby yet");
                    }
                }

                //do something
                //clear the input
                e.target.value = '';
            });

            //connect to infura
            let infuraProviderTemp = new providers.InfuraProvider(targetChainName, INFURA_KEY);

            //connect to contract
            const contractTemp = new Contract(CREATION_BABIES_ADDRESS, CREATION_BABIES_ABI, infuraProviderTemp);
            setContract(contractTemp);

            setInfuraProvider(infuraProviderTemp);

            // loadContractData();

            //ADD HEADERS TO GET MP4 RECORDING TO WORK 
            //TODO: MOVE THIS TO DIFFERENT PAGE
            console.log('changing headers for mp4 recording');

            const script = document.createElement('script');

            script.src = "coi-serviceworker.js";
            script.async = true;
            
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            }                
        }
    }, []);

    const onMouseMove = (e) => {
        if(!draggingSeparator.current) {
            return;
        }

        else if(draggingSeparator.current == true) {
            let separatorWidth = separatorRef.current.clientWidth;

            let newRight = 1.0 - (e.clientX / window.innerWidth) - (separatorWidth / window.innerWidth) / 2.0;

            separatorRef.current.style.right = newRight * 100 + '%';
            infoPaneRef.current.style.width = newRight * 100 + '%';

            if(hidArrows.current == false) {
                hideArrows();
            }

            if(camControlsRef.current) {
                //calculate offset based on newRight
                let offset = newRight * desktopFocalOffsetFactor;
                camControlsRef.current.setFocalOffset(offset, 0.0, 0.0, true);
            }
        }

    };
    
    const onMouseUp = () => {
        draggingSeparator.current = false;
    };

    const onMouseDown = (e) => {
        console.log('dragging');
        draggingSeparator.current = true;
    };

    const onTouchDown = (e) => {
        console.log('touching');
        draggingSeparator.current = true;
    };

    function hideArrows() {
        let arrowL = document.getElementsByClassName('Drag-arrow-L')[0];
        let arrowR = document.getElementsByClassName('Drag-arrow-R')[0];

        //set animation of arrowL and arrowR to fadeOut
        arrowL.style.animation = 'hue 15s infinite, wiggleL 1s infinite, fadeOut 1s forwards';
        arrowR.style.animation = 'hue 15s infinite, wiggleR 1s infinite, fadeOut 1s forwards';

        hidArrows.current = true;
    }

    const onTouchMove = (e) => {
        if(!draggingSeparator.current) {
            return;
        }

        else if(draggingSeparator.current == true) {
            let separatorWidth = separatorRef.current.offsetWidth;

            let newRight = 1.0 - (e.touches[0].clientX / window.innerWidth) - (separatorWidth / window.innerWidth) / 2.0; 

            separatorRef.current.style.right = newRight * 100 + '%';
            infoPaneRef.current.style.width = newRight * 100 + '%';

            if(hidArrows.current == false) {
                hideArrows();
            }

            if(camControlsRef.current) {
                //calculate offset based on newRight
                let offset = newRight * mobileFocalOffsetFactor;
                camControlsRef.current.setFocalOffset(offset, 0.0, 0.0, true);
            }
        }
    };

    const onTouchEnd = () => {
        draggingSeparator.current = false;
    };

    function toggleRecord() {
        recordingRef.current = !recordingRef.current;

        if(recordingRef.current) {
            recordButtonRef.current.style.backgroundColor = 'red';
            recordButtonRef.current.style.color = 'white';
            recordButtonRef.current.innerHTML = 'Stop Recording';

            console.log('beginning capture');
        
            CanvasCapture.beginVideoRecord({format: CanvasCapture.MP4});
        }

        else {
            recordButtonRef.current.style.backgroundColor = 'transparent';
            recordButtonRef.current.style.color = 'black';
            recordButtonRef.current.innerHTML = priorButtonText.current;

            //reset controls 
            camControlsRef.current.setTarget(0.0, 0.0, 0.0, true);
            //todo reset position
            camControlsRef.current.zoomTo(1.0, true);

            //get current right percentage of separator
            // let infoPaneWidth = infoPaneRef.current.offsetWidth;
            // let curSeparatorRight = (infoPaneWidth / window.innerWidth);

            // let offset = curSeparatorRight * (isMobile ? mobileFocalOffsetFactor : desktopFocalOffsetFactor);
            // camControlsRef.current.setFocalOffset(offset, 0.0, 0.0, true);

            console.log('ending capture');

            //STOP RECORDING
            CanvasCapture.stopRecord();
        }
    };

    function enablePanUp() {
        panUpRef.current = true;
    }

    function disablePanUp() {
        panUpRef.current = false;
    }

    function enablePanDown() {
        panDownRef.current = true;
    }

    function disablePanDown() {
        panDownRef.current = false;
    }

    function enablePanLeft() {
        panLeftRef.current = true;
    }

    function disablePanLeft() {
        panLeftRef.current = false;
    }

    function enablePanRight() {
        panRightRef.current = true;
    }

    function disablePanRight() {
        panRightRef.current = false;
    }

    return (
        <>
        {isMobile ? 
        <div className="Portal-error">
            Sorry, the video portal only works on desktop. Please view on a computer.
            <Leva
                collapsed// default = false, when true the GUI is collpased
                theme={levaTheme}
                hidden
            />
        </div>
        :
        <div className="Split-container">

            <div className="OneOfOneOneOne-container"
                style={{
                    width: '56.2vh',
                    height: '100vh',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <div className="Loading"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    LOADING
                </div>

                <div className='Timeline-container'
                    style={{
                        display: 'none'
                    }}
                >
                    <div className='Timeline-date'>10/25/22</div>
                    <div className='Timeline-line'> 
                        <div className='Timeline-line-thick'></div>

                        {playheadRef.current && mintedWorks.map((work, index) => {
                            return (
                                <div className='Timeline-point' 
                                    key={index} 
                                    style={{
                                        left: work.timeMintedPerc * 100 + '%', 
                                        background: work.color
                                    }}>
                                </div>
                            )
                        })
                        }
                        <div className='Timeline-playhead' ref={playheadRef}></div>
                    </div>
                    <div className='Timeline-date'>11/05/22</div>
                </div>

                <Canvas 
                    gl={{ preserveDrawingBuffer: true }}
                    dpr={[0.5, 1.0]} 
                    camera={{position:[-2,1.5,-5]}}
                    performance={{ min: 0.5 }}
                    shadows
                >
                    <AdaptiveDpr pixelated/>
                    <color attach="background" args={[bgCol]} />
                    {/* <fog attach="fog" color={fogCol} near={fogNear} far={fogFar} /> */}
                    {/* <ambientLight /> */}
                    <directionalLight 
                    position={[0, 10, 0]} 
                    color={lightCol} 
                    rotateX={[Math.PI]}
                    intensity={lightIntensity}
                    castShadow  
                    shadow-mapSize-width={2048}
                    shadow-mapSize-height={2048}
                    shadow-camera-far={shadowCameraFar}
                    shadow-camera-near={shadowCameraNear}
                    shadow-camera-left={-10}
                    shadow-camera-right={10}
                    shadow-camera-top={10}
                    shadow-camera-bottom={-10}
                    />

                    <SceneContext.Provider value={ 
                        {
                            controls: camControlsRef,
                            mintedWorkID: mintedWorkIDRef,
                            recording: recordingRef,
                            panUp: panUpRef,
                            panDown: panDownRef,
                            panLeft: panLeftRef,
                            panRight: panRightRef,
                            canRecord: canRecord,
                        } 
                    }>
                        <CamControls 
                            ref={camControlsRef}
                            minDistance={1.0} 
                            maxDistance={9.0}          
                            useFocalOffset={false}                                  
                        />

                        <Scene 
                            mintedWorks={mintedWorks}
                            heartParticlesActive={heartParticlesActive} 
                            skysphereActive={skysphereActive}
                            godRaysActive={godRaysActive}
                            ssaoActive={ssaoActive}
                            bloomActive={bloomActive}
                            CanvasCapture={CanvasCapture}
                            playTimeline={false}
                        />
                    </SceneContext.Provider>

                    <Suspense fallback={null}>
                    <Environment
                    files={['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']}
                    path={process.env.PUBLIC_URL + '/textures/env/'}
                    />
                    </Suspense>

                    {/* <Stats showPanel={0} className="stats" {...props} />        */}
                </Canvas>
                
                <Leva
                        collapsed// default = false, when true the GUI is collpased
                        theme={levaTheme}
                        hidden
                />
            </div>

            {/* {!isMobile && canRecord && <div className="RecordMintButton" ref={recordButtonRef} onClick={toggleRecord}>Record Mint Video 🔴</div>} */}

            <div className="Enter-wallet-container" ref={walletContainerRef}>
                <div ref={recordInstructions}>
                If you minted a Creation Baby, please enter your wallet address to record a mint video:<br/><br/> 
                </div>
                <input type="text" id="wallet input" ref={walletInputRef}/>
            </div>


        </div>}
        </>
    );

}
